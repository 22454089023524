import {Colors} from '../../core';
import styled from '@emotion/styled';

export const Checkbox = styled.input`
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid ${Colors.Border};
    width: 21px;
    height: 23px;
    cursor: pointer;
`
