import React, {FC, useState} from "react";
import {authService} from "../../service";
import {useHistory} from "react-router-dom";
import {SignIn} from "../../pages";
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../store/ui/RootTemplate/atoms';

export const SignInContainer: FC = () => {
    const router = useHistory();
    const [error, setError] = useState<string | null>(null);
    const [, { set: setLoading }] = useAtom(loadingAtom);

    const handleSubmit = (data: any) => {
        setError(null);
        setLoading(true);
        authService.signIn(data)
          .then(res => {
              localStorage.setItem('token', res.access_token);
              window.location.replace("/");
          })
          .catch(() => {
              setError('bad_user');
          })
          .finally(() => setLoading(false));
    }
    return (
        <SignIn handleSubmit={handleSubmit} error={error} />
    );
}
