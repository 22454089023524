import React, {
	FC,
	useState,
	ReactNode,
	HTMLAttributes,
} from 'react';

import { useAtom } from '@reatom/react';
import { useHistory } from 'react-router-dom';

import { Props } from './props';

import { Colors, generateArray } from '../../../core';

import { Button } from '../../../components/Button';
import { Container } from '../../../components/Container';

import {
	BookOutlined,
	CloseCircleOutlined, CloseOutlined,
	EditOutlined,
	PlusOutlined, SearchOutlined, SortAscendingOutlined,
	SortDescendingOutlined,
} from '@ant-design/icons';

import hexToRgba from 'hex-to-rgba';
import { Input, Skeleton, Tooltip } from 'antd';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';

type RowProps = Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'children' | 'id'> & {
	id?: number,
	title?: ReactNode,
	rest?: Record<any, any>,
	edit?: ReactNode,
	remove?: ReactNode,
	last?: boolean,
};

const Row: FC<RowProps> = ({
	id,
	title,
	edit,
	remove,
	style,
	last,
	rest,
	...restProps
}: RowProps) => {
	const router = useHistory();

	return (
		<div
			style={{
				display: 'flex',
				borderBottom: last ? 'none' : `1px solid #d7d7d7`,
				gap: 1,
				background: '#d7d7d7',
				...(title ? {} : { fontWeight: 'bold' }),
				...style
			}}
			{...restProps}
		>
			<div style={{
				background: '#fff',
				flex: 1,
				padding: '20px 15px'
			}}>
				{title || 'Имя'}
			</div>

			{rest ? (
				Object.entries(rest).map(([key, value], i) => (
					<div key={i} style={{
						background: '#fff',
						flex: 1,
						padding: '20px 15px'
					}}>
						{value || key}
					</div>
				))
			) : null}

			<div
				style={{
					background: '#fff',
					flex: '0 0 100px',
					textAlign: 'center',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-around'
				}}
				onClick={() => id ? router.push(`/admin/user/${id}`) : void 0}
			>
				{edit || 'Изменить'}
			</div>
		</div>
	);
};

export const Users: FC<Props> = ({
	users,
	perPage,
	currentPage,
	lastPage,
	handleSearch,
	query,
	...rest
}: Props) => {
	const [sort, setSort] = useState<number>(0);
	const [search, setSearch] = useState<string>('');

	const [loading] = useAtom(loadingAtom);

	const coursesSorted = sort !== 0 ? (
		sort === 1 ?
			[...users].sort((a, b) => a.name.localeCompare(b.name)) :
			[...users].sort((a, b) => b.name.localeCompare(a.name))
	) : users;

	const router = useHistory();

	return (
		<div {...rest}>
			<Container style={{ paddingTop: 200, marginBottom: 100 }}>
				<div style={{
					border: `1px solid #d7d7d7`,
					color: Colors.TextDimBlue,
					borderRadius: 8
				}}>
					<div
						className={'p-4 d-flex align-items-center'}
						style={{ borderBottom: `1px solid #d7d7d7` }}
					>
						<span style={{
							display: 'block',
							flex: 1,
							fontSize: 24,
							textTransform: 'uppercase',
							fontWeight: 'bold'
						}}>
							Пользователи
						</span>

						<div
							className={'d-flex align-items-center'}
							style={{ gap: 10 }}
						>
							<Button
								style={{
									fontSize: 14,
									fontWeight: 500,
									width: 'auto'
								}}
								onClick={() => router.push('/admin')}
							>
								<BookOutlined className={'mr-1'} />
								Курсы
							</Button>

							<Button style={{
								fontSize: 14,
								fontWeight: 500,
								background: Colors.TextDimBlue,
								color: '#fff',
								border: 'none',
								width: 'auto'
							}} onClick={() => router.push('/admin/user')}>
								<PlusOutlined /> &nbsp;
								Добавить пользователя
							</Button>
						</div>
					</div>
					{loading.status ? (
						<div className={'p-4'}>
							<Skeleton paragraph={{ rows: 10 }} />
						</div>
					) : (
						<>
							<div className={'p-4'}>
								<div className={'d-flex align-items-center justify-content-between'}>
									<div className={'d-flex'}>
										<Input
											value={search}
											onChange={(e) => setSearch(e.currentTarget.value)}
											placeholder={'Поиск по имени, электронной почте...'}
										/>

										<Button
											style={{
												fontSize: 14,
												width: 'auto',
												padding: '5px 15px',
												marginLeft: 10,
											}}
											onClick={() => handleSearch(search)}
										>
											<SearchOutlined />
										</Button>

										{query?.length ? (
											<Button
												style={{
													background: Colors.TextDimBlue,
													color: '#fff',
													border: 'none',
													fontSize: 14,
													width: 'auto',
													padding: '5px 15px',
													marginLeft: 10,
												}}
												onClick={() => router.push(`/admin/users/${currentPage}`)}
											>
												<CloseOutlined />
											</Button>
										) : null}
									</div>
								</div>
							</div>
							<div className="px-4 pb-4 pt-0">
								<div style={{
									border: `1px solid #d7d7d7`,
									color: Colors.TextDimBlue,
									borderRadius: 8,
									overflow: 'hidden'
								}}>
									<Row title={(
										<>
											{sort === 0 ? (
												<div>
													<Tooltip title={'Сортировать по алфавиту (A-Z)'}>
														<span
															className={'d-inline-flex align-items-center '}
															css={{ gap: 10, transition: 'color 0.2s', cursor: 'pointer', '&:hover': { color: Colors.Primary } }}
															onClick={() => setSort(1)}
														>
															<SortAscendingOutlined />
															<span>Имя</span>
														</span>
													</Tooltip>
												</div>

											) : null}
											{sort === 1 ? (
												<div>
													<Tooltip title={'Сортировать по алфавиту (Z-A)'}>
														<span
															className={'d-inline-flex align-items-center'}
															css={{
																gap: 10,
																transition: 'color 0.2s',
																cursor: 'pointer',
																'&:hover': { color: Colors.Primary }
															}}
															onClick={() => setSort(2)}
														>
															<SortDescendingOutlined />
															<span>Имя</span>
														</span>
													</Tooltip>
												</div>
											) : null}
											{sort === 2 ? (
												<div>
													<Tooltip title={'Не сортировать'}>
														<span
															className={'d-inline-flex align-items-center'}
															css={{
																gap: 10,
																transition: 'color 0.2s',
																cursor: 'pointer',
																'&:hover': { color: Colors.Primary }
															}}
															onClick={() => setSort(0)}
														>
															<CloseCircleOutlined />
															<span>Имя</span>
														</span>
													</Tooltip>
												</div>
											) : null}
										</>
									)} rest={{ 'Фамилия': null, 'Email': null }} edit={'Изменить'} style={{ fontWeight: 'bold' }} />
									<div>
										{coursesSorted.map((n, i) => (
											<Row
												id={n.id}
												title={n.name}
												key={i}
												last={i === coursesSorted.length - 1}
												rest={{
													'Фамилия': n.second_name,
													'Email': n.email
												}}
												edit={
													<Button style={{
														fontSize: 14,
														fontWeight: 500,
														width: 'auto'
													}}>
														<EditOutlined />
													</Button>
												}
											/>
										))}
									</div>
								</div>
							</div>

							<div
								className={'px-4 pb-4 d-flex align-items-center'}
								style={{ gap: 10 }}
							>
								{generateArray(lastPage).map((page, i) => (
									<Button
										key={i}
										css={{
											fontSize: 14,
											fontWeight: 500,
											padding: '7px 15px',
											width: 'auto',
											...(currentPage === page + 1 ? {} : {
												background: 'transparent',
												border: `1px solid ${Colors.TextDimBlue}`,
												'&:hover': {
													background: hexToRgba(Colors.TextDimBlue, .1)
												}
											})
										}}
										onClick={() => router.push(`/admin/users/${page + 1}`)}
									>
										{page + 1}
									</Button>
								))}

								{currentPage !== lastPage ? (
									<Button
										css={{
											fontSize: 14,
											fontWeight: 500,
											width: 'auto',
											padding: '7px 15px',
											background: 'transparent',
											border: `1px solid ${Colors.TextDimBlue}`,
											'&:hover': {
												background: hexToRgba(Colors.TextDimBlue, .1)
											}
										}}
										onClick={() => router.push(`/admin/users/${currentPage + 1}`)}
									>
										Вперед
									</Button>
								) : null}
							</div>
						</>
					)}
				</div>
			</Container>
		</div>
	);
};
