import React, {FC} from "react";
import {Props} from "./props";
import { Colors, generateArray } from '../../core';
import {CourseCard} from "../../components/CourseCard";
import { Container } from '../../components/Container';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../store/ui/RootTemplate/atoms';
import { Skeleton } from 'antd';
import { Media } from '../../components/Media';

export const Courses: FC<Props> = ({ courses, ...rest }: Props) => {
  const [loading] = useAtom(loadingAtom);
  return (
    <div {...rest}>
      <Media>
        {(matches: any) => (
          <Container style={{ paddingTop: matches.large ? 200 : 20, textAlign: 'center', paddingBottom: 200 }}>
            <h1 style={{
              fontSize: matches.large ? 50 : 30,
              color: Colors.TextDimBlue,
              margin: 0
            }}>Курсы</h1>
            <div style={{
              display: 'grid',
              gridTemplateColumns: matches.large ? '1fr 1fr 1fr 1fr' : (matches.medium ? '1fr 1fr' : '1fr'),
              gap: 30,
              marginTop: matches.large ? 65 : 30
            }}>
              {courses.map((n, i) => (
                <CourseCard item={n} key={i} />
              ))}
              {loading.status ? (generateArray(4).map((n, i) => (
                <div key={i}>
                  <Skeleton paragraph={{ rows: 5 }} />
                </div>
              ))) : null}
            </div>
          </Container>
        )}
      </Media>
    </div>
  );
}
