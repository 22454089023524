import React, {FC, HTMLAttributes} from "react";
import {Colors} from "../../../core/colors";
import {Container} from "../../../components/Container";
import {CourseCard} from "../../../components/CourseCard";
import { generateArray } from '../../../core';
import { Skeleton } from 'antd';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';
import { Media } from '../../../components/Media';

type Props = HTMLAttributes<HTMLDivElement> & {
    readonly items: ReadonlyArray<any>;
}

export const Courses: FC<Props> = ({ items, style, ...rest }: Props) => {
  const [loading] = useAtom(loadingAtom);
  return (
    <Media>
      {(matches: any) => (
        <div style={{
          padding: matches.large ? '150px 0' : '60px 0',
          ...style
        }} {...rest}>
          <Container>
            <h1 style={{
              fontSize: matches.large ? 50 : 30,
              color: Colors.TextDimBlue,
              margin: 0,
              textAlign: 'center'
            }}>Курсы</h1>
            <div style={{
              display: 'grid',
              gridTemplateColumns: matches.small ? '1fr' : '1fr 1fr 1fr 1fr',
              gap: 30,
              marginTop: matches.large ? 65 : 30
            }}>
              {items.map((n, i) => (
                <CourseCard item={n} key={i} />
              ))}
            </div>
            {loading.status ? (
              <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: 30,
                marginTop: 65
              }}>
                {generateArray(3).map((n, i) => (
                  <div key={i}>
                    <Skeleton paragraph={{ rows: 5 }} />
                  </div>
                ))}
              </div>
            ) : null}
          </Container>
        </div>
      )}
    </Media>
  );
}
