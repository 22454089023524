import React, { FC } from 'react';
import { Skeleton } from 'antd';
import hexToRgba from 'hex-to-rgba';
import { Colors, getAvatar } from '../../../../core';
import { Link, useHistory } from 'react-router-dom';
import { useAtom } from '@reatom/react';
import { userAtom } from '../../../../store/modules/user';
import { Media } from '../../../Media';
import styled from '@emotion/styled';
import { NavigationLink } from '../NavigationLink';

const Button = styled.button`
  padding: 9px 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;
  :hover {
      text-decoration: underline;
  }
`;

const SignInButton = styled(Button)`
  color: ${Colors.TextDimBlue};
  border: none;
  background-color: ${Colors.YellowBG};
  border-radius: 10px;
  padding: 7px 25px;
`;

const RegisterButton = styled(Button)`
  color: #FF9900;
  border: 1px solid #FF9900;
  background-color: #FFC99833;
  border-radius: 6px;
  padding: 7px 20px;
`;


export const ProfileNavigation: FC = () => {
  const [user] = useAtom(userAtom);
  const router = useHistory();
  return (
    <Media>
      {(matches: any) => (
        <div className={'d-flex align-items-center'} style={{ gap: 20 }}>
          {user.loading ? <Skeleton.Input style={{ width: 200 }} /> : (
            !user.authenticated ? (
              <>
                {matches.large ? (
                  <>
                    <NavigationLink
                      className={'mr-3'}
                      onClick={() => router.push('/auth/signin')}
                    >Войти</NavigationLink>
                    <RegisterButton onClick={() => router.push('/auth/signup')}>
                      Регистрация
                    </RegisterButton>
                  </>
                ) : (
                  <>
                    <RegisterButton {...(matches.small ? { style: { padding: '5px 20px', fontSize: 16, fontWeight: 500, color: Colors.TextDimBlue + '!important' } } : {})} onClick={() => router.push('/auth/signin')}>Войти</RegisterButton>
                  </>
                )}
              </>
            ) : (
              <>
                {matches.large ? (
                  <>
                    <div
                      className={'d-flex align-items-center pl-4'}
                      css={{
                        cursor: 'pointer',
                        gap: 20,
                        borderLeft: `1px solid ${hexToRgba('#000', .1)}`,
                        transition: 'color 0.2s',
                        '&:hover': {
                          color: Colors.Primary
                        }
                      }}
                      onClick={() => router.push('/profile/courses')}
                    >
                      <span className={'d-block mx-2 ml-3'}>Мой профиль</span>
                      <img src={getAvatar(user.data.photo)} alt={user.data?.name} style={{ height: 30, width: 30, background: Colors.YellowBG, borderRadius: 100 }} />
                    </div>

                    {user.data?.role ? (
                      <Link
                        className={'d-block ml-3'}
                        to={'/admin'}
                      >
                        Администрация
                      </Link>
                    ) : null}

                    <span
                      className={'d-block ml-3'}
                      style={{ cursor: 'pointer' }}
                      css={{
                        transition: 'color 0.2s',
                        '&:hover': {
                          color: Colors.Primary
                        }
                      }}
                      onClick={() => {
                        localStorage.removeItem('token');
                        window.location.replace('/');
                      }}
                    >
                      Выйти
                    </span>
                  </>
                ) : (
                  <>
                    <div
                      className={'d-flex align-items-center pl-4'}
                      css={{
                        cursor: 'pointer',
                        gap: 20,
                        transition: 'color 0.2s',
                        '&:hover': {
                          color: Colors.Primary
                        }
                      }}
                      onClick={() => router.push('/profile/courses')}
                    >
                      <img src={getAvatar(user.data.photo)} alt={user.data?.name} style={{ height: 40, width: 40, background: Colors.YellowBG, borderRadius: 100 }} />
                    </div>
                  </>
                )}

              </>
            )
          )}
        </div>
      )}
    </Media>
  );
}
