import React, { FC, useEffect } from 'react';
import { ProfileCertificates } from '../../../pages/Profile';
import { useAtom } from '@reatom/react';
import { publicCoursesAtom } from '../../../store/modules/courses';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';
import { cabinetAtom } from '../../../store/modules/user';

export const CertificatesContainer: FC = () => {
  const [cabinet, { get: getCabinet }] = useAtom(cabinetAtom);
  const [, { set: setLoading }] = useAtom(loadingAtom);

  useEffect(() => {
    getCabinet();
  }, [getCabinet]);

  useEffect(() => {
    setLoading(cabinet.loading);
  }, [cabinet.loading, setLoading]);

  return (
    <ProfileCertificates certificates={cabinet.data?.purchased_courses?.filter((n: any) => n.cert) || []} />
  );
};
