import React, { 
	FC,
	useState,
	HTMLAttributes,
} from 'react';

import { useHistory } from 'react-router-dom';

import styled from "@emotion/styled";

import hexToRgba from 'hex-to-rgba';
import { Card, Typography } from 'antd';

import { Colors } from '../../../core';

import { SearchOutlined } from "@material-ui/icons";
import { Input } from "../../../components/Form/Input";
import { Media } from '../../../components/Media';
import { Button } from "../../../components/Button";
import { Container } from "../../../components/Container";
import { LoadingOutlined } from '@ant-design/icons';

const PlayButton = styled.button({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: 85,
    height: 85,
    border: 'none',
    background: '#fff',
    borderRadius: '100%',
    boxShadow: '0px 6px 15px rgba(57, 60, 72, 0.25)',
    color: Colors.Primary,
    cursor: 'pointer',
    zIndex: 10,
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'translateX(-50%) translateY(-50%) scale(1.1)'
    }
});

type Props = HTMLAttributes<HTMLDivElement> & {
  readonly handleSearch: (term: string) => void;
  readonly searchResult: ReadonlyArray<any>;
  readonly searchNotFound?: boolean;
  readonly searchLoading?: boolean;
};

export const Main: FC<Props> = ({ 
	handleSearch,
	searchResult,
	searchNotFound,
	searchLoading,
	...rest
}: Props) => {
    const router = useHistory();

    const [term, setTerm] = useState<string>('');
    const [showSearchResult, setShowSearchResult] = useState<boolean>(false);

    return (
        <div {...rest}>
          <Media>
            {(matches: any) => (
              <Container
                size={'1440px'}
                style={{
                  padding: matches.large ? '225px 0' : '30px 0',
                  ...(matches.large ? {
                    background: `url(/images/main-picture.png)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top right',
                  } : {
                    background: hexToRgba(Colors.PlaceholderBG, .4),
                    paddingBottom: 50
                  })
                }}
              >
                <Container>
                  <div style={{
                    display: 'flex',
                    gap: 40,
                    textAlign: 'left',
                  }}>
                    <div style={{
                      maxWidth: 600,
                      width: '100%',
                      marginRight: 'auto',
                      ...(matches.medium ? {
                        margin: 'auto'
                      } : {})
                    }}>
                      <h1 style={{
                        fontSize: matches.large ? 50 : 40,
                        lineHeight: matches.large ? 1.3 : 1.2,
                        fontWeight: 'bold',
                        color: Colors.TextDimBlue,
                        margin: 0
                      }}>
                        Обучайся в любое время, в любом месте на любом устройстве
                      </h1>

                      <p style={{
                        fontSize: 18,
                        lineHeight: '27px',
                        marginTop: 20,
                        maxWidth: matches.large ? 300 : '100%',
                      }}>
                        Пройди обучение и развивай свои профессиональные навыки при помощи наших онлайн-курсов
                      </p>

                      <div style={{
                        display: 'flex',
                        gap: 15,
                        marginTop: matches.large ? 45 : 30,
                        width: '100%'
                      }}>
                        <label style={{
                          position: 'relative',
                          ...(matches.large ? {} : { flex: 1 })
                        }}>
                          <SearchOutlined style={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%) scale(1.4)',
                            left: 25,
                            color: Colors.Placeholder,
                          }} />

                          <Input
                            type={'text'}
                            placeholder={'Что вы хотите изучить'}
                            value={term}
                            onChange={(e) => {
                              setTerm(e.currentTarget.value);
                              handleSearch(e.currentTarget.value);
                            }}
                            onFocus={() => setShowSearchResult(true)}
                            onBlur={() => setTimeout(() => setShowSearchResult(false), 300)}
                            style={{
                              margin: 0,
                              paddingLeft: 70,
                              ...(matches.large ? {
                              } : {
                                width: '100%',
                                borderWidth: 2,
                              })
                            }}
                          />

                          {searchResult.length || searchNotFound || searchLoading ? (
                            <Card
                              className={'search-dropdown'}
                              style={{
                                position: 'absolute',
                                width: '100%',
                                borderRadius: 6,
                                boxShadow: '0px 9px 20px -5px rgba(0,0,0,.2)',
                                zIndex: 10,
                                display: showSearchResult ? 'block' : 'none'
                              }}
                              bodyStyle={{ padding: 0 }}
                            >
                              {searchResult.map((n, i) => (
                                <div
                                  key={i}
                                  css={{
                                    padding: '10px 20px',
                                    color: Colors.TextDimBlue,
                                    cursor: 'pointer',
                                    transition: 'background 0.2s',
                                    '&:hover': {
                                      background: hexToRgba(Colors.TextDimBlue, .1)
                                    }
                                  }}
                                  onClick={() => router.push(`/course/${n.id}`)}
                                >
                                  <Typography.Title
                                    level={5}
                                    className={'m-0'}
                                  >
                                    {n?.title}
                                  </Typography.Title>
                                  <Typography.Paragraph className={'m-0'}>{n?.short_description}</Typography.Paragraph>
                                </div>
                              ))}
                              {searchNotFound ? (
                                <span style={{
                                  padding: '10px 20px',
                                  fontWeight: 500,
                                  display: 'block',
                                  textAlign: 'center',
                                  color: 'grey'
                                }}>Ничего не найдено</span>
                              ) : null}
                              {searchLoading ? (
                                <div className={'p-4 text-center'} style={{ fontSize: 30, color: Colors.Primary }}>
                                  <LoadingOutlined />
                                </div>
                              ) : null}
                            </Card>
                          ) : null}
                        </label>
						
                        {!matches.small ? (
                          <Button
                            style={{
                              fontSize: 16,
                              display: 'block',
                              width: 'auto',
                              color: '#fff',
                              textTransform: 'capitalize',
                              padding: '0 40px'
                            }}
                            onClick={() => handleSearch(term)}
                          >
                            Поиск
                          </Button>
                        ) : null}
                      </div>
                    </div>

                    {matches.large ? (
                        <div style={{
						  width: '100%',
                          maxWidth: 516,
						  height: 334,
                          border: '4px solid #FFFFFF',
						  overflow: 'hideen',
                          borderRadius: 6,
						  backgroundColor: '#FFFFFF',
                          boxShadow: '0px 6px 15px rgba(57, 60, 72, 0.25)'
                        }}>
							<img
								src="/images/poster.jpg" 
								alt="image"
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									borderRadius: 'inherit',
									objectPosition: 'center',
								}} 
							/>
                        </div>
                    ) : null}
                  </div>
                </Container>
              </Container>
            )}
          </Media>
        </div>
    );
}
