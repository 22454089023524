import {Colors} from "../../core";
import styled from '@emotion/styled';

export const MainFormInput = styled.input`
  border: 1px solid ${Colors.Border};
  margin-bottom: 21px;
  padding: 15px;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
  line-height: 18px;
  ::placeholder {
    color: ${Colors.Placeholder};
    opacity: .5;
  }
`;
