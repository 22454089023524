import React, {FC, useMemo, useState} from "react";
import { Link, useHistory } from 'react-router-dom';
import { Input } from "../../components/Form/Input";
import { Button } from "../../components/Button";
import styled from "@emotion/styled";
import {Props} from "./props";
import {Container} from "../../components/Container";
import {Divider} from "../../components/Divider";
import {Error} from "../../components/Error";
import { Media } from '../../components/Media';
import { Colors } from '../../core';

const BottomNav = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 27px;
    a {
        color: #131135;
        font-size: 16px;
        line-height: 18px;
        margin-right: 21px;
        text-decoration: none;
    }
    a:hover {
        color: #FFBF5F;
    }
`;

export const NewPassword: FC<Props> = ({ handleSubmit, error, success }: Props) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const router = useHistory();

    const data = useMemo(() => ({
      password,
      confirmPassword
    }), [password, confirmPassword]);

    return (
      <Media>
        {(matches: any) => (
          <div className={'text-center'} style={{ background: '#FFFCFC', height: '100%' }}>
            <Container size={'540px'}>
              <img src={'/images/proftime.png'} alt={'logo'} onClick={() => router.push('/')} style={{
                marginBottom: 50,
                marginTop: 120,
                ...(!matches.large ? {
                  height: 75
                } : {})
              }} />
              <h2 style={{
                fontSize: 24,
                lineHeight: '28px',
                marginBottom: 9
              }}>
                Изменить пароль
              </h2>
              <p style={{
                fontSize: 16,
                lineHeight: '18px',
                marginTop: 0,
                marginBottom: 50
              }}>
                Введите новый пароль для вашего аккаунта
              </p>
              {error ? (
                <Error style={{ marginBottom: 30 }} message={
                  error
                } />
              ) : null}
              {success ? (
                <Error style={{ marginBottom: 30 }} success message={
                  'Ваш пароль успешно изменен!'
                } />
              ) : null}
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 38
              }}>
                <Input type={'password'} onChange={(e) => setPassword(e.currentTarget.value)} placeholder='Введите пароль' />
                <Input type={'password'} onChange={(e) => setConfirmPassword(e.currentTarget.value)} placeholder='Введите подтверждение пароль' />
                <Button style={{ fontSize: 20, color: '#fff' }} onClick={() => handleSubmit(data)}>
                  Сохранить
                </Button>
              </div>
            </Container>
          </div>
        )}
      </Media>
    );
}
