import styled from "@emotion/styled";
import { Colors } from '../../core';

export const Textarea = styled.textarea`
  resize: none;
  margin-bottom: 20px;
  outline: none;
  border: 1px solid #FFE6E1;
  border-radius: 8px;
  height: 150px;
  font-size: 16px;
  font-family: inherit;
  padding: 15px;
  &::placeholder {
    color: ${Colors.Placeholder};
    opacity: 0.5;
  }
  &.default::placeholder {
    color: ${Colors.Placeholder};
    opacity: 1;
  }
`;
