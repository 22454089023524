import {createAtom} from "@reatom/core";
import { coursesService } from '../../../service';

const defaultState = {
  loading: false,
  loaded: false,
  data: [],
  notFound: false,
  error: null,
};

export const searchCoursesAtom = createAtom(
  { get: (term: string) => term, reset: () => {}, _mutate: (newState: any) => newState },
  ({ onAction, create, schedule, get }, state: any = defaultState) => {

    onAction('_mutate', (newState) => (state = newState));
    onAction('reset', () => {
      schedule((dispatch => {
        dispatch(create('_mutate', defaultState));
      }))
    });

    onAction('get', (term) => {
      schedule((dispatch) => {
        dispatch(create('_mutate', { ...defaultState, loading: true }));
        coursesService.search(term)
          .then(response => {
            dispatch(create('_mutate', { ...defaultState, loaded: true, data: response.data.result }));
          })
          .catch(e => {
            if (e.response?.status === 404) {
              dispatch(create('_mutate', { ...defaultState, loaded: true, notFound: true }));
            } else dispatch(create('_mutate', { ...defaultState, error: e }));
          });
      });
    });

    return state;
  });
