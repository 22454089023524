import React, { FC, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { Props } from './props';
import { Colors, getAvatar, getImage } from '../../../core';
import { Container } from '../../../components/Container';
import { Input } from '../../../components/Form/Input';
import { Button } from '../../../components/Button';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';
import { Accordion } from '../../../components/Accordion';
import { AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Textarea } from '../../../components/Form/Textarea';
import {
  CheckCircleFilled,
  CloseOutlined,
  DeleteOutlined,
  HolderOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import hexToRgba from 'hex-to-rgba';
import { Card, message, Modal, Select, Typography } from 'antd';
import { getAlphabet } from '../../../core/getAlphabet';
import { usersService } from '../../../service';
import _ from 'lodash';

export const CoursePage: FC<Props> = ({ handleAdd, handleEdit, item, handleUpload, questions, ...rest }: Props) => {
  const [loading] = useAtom(loadingAtom);

  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [plans, setPlans] = useState('');
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [chapterCurrent, setChapterCurrent] = useState('');
  const [chapters, setChapters] = useState<Array<any>>([]);

  const [chapterSelected, setChapterSelected] = useState<any>();
  const [lectureCurrent, setLectureCurrent] = useState({
    name: '',
    videofile: ''
  });
  const [files, setFiles] = useState<FileList | null>(null);
  const [avatarModal, setAvatarModal] = useState(false);


  const [landing, setLanding] = useState(false);
  const [status, setStatus] = useState(false);
  const [tests, setTests] = useState<Array<any>>([]);

  useEffect(() => {
    if(files?.length) {
      const form = new FormData();
      form.append('image', files[0]);
      handleUpload ? handleUpload(form) : void 0;
      setFiles(null);
      setAvatarModal(false);
    }
  }, [files, handleUpload]);

  useEffect(() => {
    if (item) {
      setTitle(item.title);
      setShortDescription(item.short_description);
      setLongDescription(item.long_description);
      setPlans(item.course_plans?.map((n: any) => n.name).join(' '));
      setPrice(item.price / 100);
      setDiscount(item.discount * 100);
      setChapterSelected(0);
      setChapters(item.course_chapters);
      setLanding(item.show_on_landing);
      setStatus(item.status);
      if (questions?.length) setTests(questions);
    }
  }, [item, questions]);

  const data = {
    title,
    short_description: shortDescription,
    long_description: longDescription,
    price: price * 100,
    discount: discount / 100,
    chapters,
    tests: tests.map((n) => ({ ...(_.omit(n, 'id')), answers: n.answers.map((m: any) => _.omit(m, 'id')) })),
    plans: plans.split(' '),
    show_on_landing: landing,
    status
  };

  const handleClick = () => {
    if (handleAdd) {
      handleAdd(data);
    } else if(handleEdit) {
      handleEdit(data);
    }
  }

  const deletePlan = (index: number) => {
    setPlans(prev => prev.split(' ').filter((n, i) => i !== index).join(' '));
  };

  const onSortEnd = (idChapter: number | undefined = undefined) => ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }) => {
    if (idChapter !== undefined) {
      setChapters(prev => prev.map((n, i) => {
        if (i === idChapter) {
          return ({ ...n, lectures: arrayMove(n.lectures, oldIndex, newIndex) });
        } else return n;
      }));
    } else {
      setChapters(prev => arrayMove(prev, oldIndex, newIndex));
    }
  };

  const onTestsSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }) => {
    setTests(prev => {

      return arrayMove(prev, oldIndex, newIndex)
    });
  };

  const onAnswersSortEnd = (idTest: number) => ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }) => {
    setTests(prev => prev.map((n, i) => {
      if (i === idTest) {
        return ({ ...n, answers: arrayMove(n.answers, oldIndex, newIndex) });
      } else return n;
    }));
  };

  const DragHandle = SortableHandle(() => <span style={{
    fontSize: 30,
    color: hexToRgba(Colors.TextDimBlue, .3),
    cursor: 'move',
    paddingRight: 15
  }}><HolderOutlined /></span>);

  type SortableItemProps = Omit<HTMLAttributes<HTMLDivElement>, 'id'> & { value: any, id: number, idChapter?: number };
  const SortableItemBase: FC<SortableItemProps> = ({value, id, idChapter, ...rest}: SortableItemProps) => {
    const [tmpName, setTmpName] = useState(value?.name || '');
    const [tmpVideofile, setTmpVideofile] = useState(value?.videofile || '');
    return (
      <div className="d-flex" {...rest}>
        <DragHandle />
        <Input
          value={tmpName}
          className={'w-100 m-0'}
          placeholder={idChapter ? 'Изменить название лекции' : 'Изменить название главы'}
          onChange={(e) => setTmpName(e.currentTarget.value)}
          onBlur={() => {
            if (idChapter !== undefined) {
              setChapters((prev: any) => prev.map((n: any, i: number) => {
                if (i === idChapter) {
                  return ({ ...n, lectures: n.lectures.map((m: any, im: number) => {
                      if (im === id) {
                        return {
                          ...m,
                          name: tmpName
                        };
                      } else return m;
                    })
                  });
                } else return n;
              }));
            } else {
              setChapters((prev: any) => prev.map((n: any, i: number) => {
                if (i === id) {
                  return {
                    ...n,
                    name: tmpName
                  };
                } else return n;
              }));
            }
          }}
        />
        {idChapter !== undefined ? (
          <Input
            value={tmpVideofile}
            className={'w-100 m-0 ml-3'}
            placeholder={'Видео'}
            onChange={(e) => setTmpVideofile(e.currentTarget.value)}
            onBlur={() => {
              if (idChapter !== undefined) {
                setChapters((prev: any) => prev.map((n: any, i: number) => {
                  if (i === idChapter) {
                    return ({ ...n, lectures: n.lectures.map((m: any, im: number) => {
                        if (im === id) {
                          return {
                            ...m,
                            videofile: tmpVideofile
                          };
                        } else return m;
                      })
                    });
                  } else return n;
                }));
              } else {
                setChapters((prev: any) => prev.map((n: any, i: number) => {
                  if (i === id) {
                    return {
                      ...n,
                      videofile: tmpVideofile
                    };
                  } else return n;
                }));
              }
            }}
          />
        ) : null}
        <Button
          style={{
            width: 'auto',
            fontSize: 16,
            padding: '10px 20px',
            marginLeft: 20
          }}
          onClick={() => {
            if (idChapter !== undefined) {
              setChapters((prev: any) => prev.map((n: any, i: number) => {
                if (i === idChapter) {
                  return ({ ...n, lectures: n.lectures.filter((m: any, im: number) => im !== id) });
                } else return n;
              }));
            } else {
              setChapters((prev: any) => prev.filter((n: any, i: number) => i !== id));
              chapters?.length ? setChapterSelected(0) : void 0;
            }
          }}
        ><DeleteOutlined/></Button>
      </div>
    );
  }

  const SortableItem = SortableElement(SortableItemBase);

  const SortableList = SortableContainer(({items, idChapter}: { items: ReadonlyArray<any>, idChapter?: number }) => {
    return (
      <div className={'d-flex my-3'} style={{ gap: 10, flexDirection: 'column' }}>
        {items?.map((value, index) => (
          <SortableItem key={`item-${idChapter ? idChapter : ''}-${index}`} index={index} id={index} value={value} idChapter={idChapter} />
        ))}
      </div>
    );
  });

  type AnswerItemProps = Omit<HTMLAttributes<HTMLDivElement>, 'id'> & { value: any, id: number, idTest: number };
  const AnswerItem: FC<AnswerItemProps> = ({value, id, idTest, ...rest}: AnswerItemProps) => {
    const [tmpName, setTmpName] = useState(value.content);
    return (
      <div className={'d-flex align-items-center pb-3'}>
        <DragHandle />
        <span style={{
          fontSize: 18,
          fontWeight: 500,
          padding: '0 10px',
          paddingRight: 30
        }}>{getAlphabet[id]}</span>
        <Input
          defaultValue={tmpName}
          onChange={(e) => {
            const val = e.currentTarget.value;
            setTmpName(val);
          }}
          className={'w-100 m-0'}
          placeholder={'Вариант ответа'}
          onBlur={() => {
            setTests(prev => prev.map((n, i) => {
              if (i === idTest) {
                return ({
                  ...n,
                  answers: n.answers.map((m: any, im: number) => {
                    if (im === id) {
                      return ({ ...m, content: tmpName });
                    } else return m;
                  })
                });
              } else return n;
            }));
          }}
        />
        <span
          style={{
            display: 'inline-block',
            marginLeft: 30,
            cursor: 'pointer',
            ...(value.is_correct ? { color: Colors.Primary } : {})
          }}
          onClick={() => {
            setTests(prev => prev.map((n, i) => {
              if (i === idTest) {
                return ({
                  ...n,
                  answers: n.answers.map((m: any, im: number) => {
                    if (im === id) {
                      return ({ ...m, is_correct: !m.is_correct });
                    } else return m;
                  })
                });
              } else return n;
            }));
          }}
        >
          <CheckCircleFilled />
        </span>
        <span
          style={{
            width: 'auto',
            fontSize: 16,
            padding: '10px 20px',
            marginLeft: 15,
            color: Colors.YellowBG,
            cursor: 'pointer'
          }}
          onClick={() => {
            setTests(prev => prev.map((n, i) => {
              if (i === idTest) {
                return ({
                  ...n,
                  answers: n.answers.filter((m: any, im: number) => im !== id)
                });
              } else return n;
            }));
          }}
        ><DeleteOutlined /></span>
      </div>
    )
  }
  const SortableAnswerItem = SortableElement(AnswerItem);

  const SortableAnswersList = SortableContainer(({items, idTest}: { items: ReadonlyArray<any>, idTest: number }) => {
    return (
      <div className={'d-flex my-3'} style={{ gap: 10, flexDirection: 'column' }}>
        {items?.map((value, index) => (
          <SortableAnswerItem key={`item-${index}`} index={index} id={index} value={value} idTest={idTest} />
        ))}
      </div>
    );
  });

  type TestItemProps = Omit<HTMLAttributes<HTMLDivElement>, 'id'> & { value: any, id: number };
  const TestItem: FC<TestItemProps> = ({value, id, ...rest}: TestItemProps) => {
    const [tmpName, setTmpName] = useState(value.content);
    return (
      <Card style={{
        borderRadius: 6,
        border: 'none'
      }}>
        <div className={'d-flex align-items-center pb-3'}>
          <DragHandle />
          <span style={{
            fontSize: 18,
            fontWeight: 500,
          }}>Вопрос №{id+1}</span>
        </div>
        <Input
          defaultValue={tmpName}
          onChange={(e) => {
            const val = e.currentTarget.value;
            setTmpName(val);
          }}
          className={'w-100 m-0'}
          placeholder={'Изменить вопрос'}
          onBlur={() => {
            setTests(prev => prev.map((n, i) => {
              if (i === id) {
                return ({ ...n, content: tmpName });
              } else return n;
            }));
          }}
        />
        <SortableAnswersList useDragHandle items={tests[id]?.answers} onSortEnd={onAnswersSortEnd(id)} idTest={id} />
        <Button
          style={{
            fontSize: 16,
            padding: '10px 20px',
            background: Colors.TextDimBlue,
            border: 'none',
            color: '#fff'
          }}
          className={'mb-2'}
          onClick={() => {
            setTests((prev: any) => prev.map((n: any, i: number) => {
              if (i === id) {
                return ({ ...n, answers: [...n.answers, { content: '', is_correct: false } ] });
              } else return n;
            }));
          }}
        >Добавить вариант</Button>
        <Button
          style={{
            fontSize: 16,
            padding: '10px 20px',
            background: Colors.TextDimBlue,
            border: 'none',
            color: '#fff'
          }}
          onClick={() => {
            setTests((prev: any) => prev.filter((n: any, i: number) => i !== id));
          }}
        >Удалить вопрос</Button>
      </Card>
    )
  }
  const SortableTestItem = SortableElement(TestItem);

  const SortableTestList = SortableContainer(({items}: { items: ReadonlyArray<any> }) => {
    return (
      <div className={'d-flex my-3'} style={{ gap: 10, flexDirection: 'column' }}>
        {items.map((value, index) => (
          <SortableTestItem key={`item-${index}`} index={index} id={index} value={value} />
        ))}
      </div>
    );
  });

  return (
    <div {...rest}>
      <Container style={{ paddingTop: 200, marginBottom: 100 }}>
        <div className={'py-5 px-0 text-center'}>
          <Accordion className={'mb-3'}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span style={{
                fontSize: 18,
                lineHeight: '28px',
                color: Colors.TextDimBlue,
                fontWeight: 'bolder'
              }}>{handleEdit ? 'Редактирование курса' : 'Добавить новый курс'}</span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="p-4">
                <Input disabled={loading.status} value={title} onChange={(e) => setTitle(e.currentTarget.value)} className={'w-100'} placeholder={'Название'} />
                <Textarea className={'w-100 p-4 default'} placeholder={'Описание'} onChange={(e) => setShortDescription(e.currentTarget.value)} value={shortDescription} />
              </div>
            </AccordionDetails>
          </Accordion>

          {item ? (
            <Accordion className={'mb-3'}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <span style={{
                fontSize: 18,
                lineHeight: '28px',
                color: Colors.TextDimBlue,
                fontWeight: 'bolder'
              }}>Изменить/добавить картинку курса</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="p-4">
                  <img
                    src={getImage(item?.title_image)}
                    alt={item?.title }
                    onClick={() => setAvatarModal(true)}
                    css={{
                      height: 120,
                      background: Colors.TextDimBlue,
                      bottom: 0,
                      cursor: 'pointer',
                      transition: 'transform 0.2s, filter 0.2s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        filter: 'hue-rotate(45deg)'
                      }
                    }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          ) : null}

          <Accordion className={'mb-3'}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span style={{
                fontSize: 18,
                lineHeight: '28px',
                color: Colors.TextDimBlue,
                fontWeight: 'bolder'
              }}>Изменить/добавить описание курса</span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="p-4">
                <Input disabled={loading.status} value={plans} onChange={(e) => setPlans(e.currentTarget.value)} className={'w-100'} placeholder={'Что включает в себя курс (через пробел)'} />
                {plans?.length ? (
                  <div className={'d-flex flex-wrap align-items-center mb-4'} style={{ gap: 10 }}>
                    {plans.split(' ').map((plan, i) => (
                      <div key={i} css={{
                        background: Colors.YellowBG,
                        color: Colors.TextDimBlue,
                        fontSize: 16,
                        fontWeight: 500,
                        padding: '7px 10px',
                        borderRadius: 4,
                        cursor: 'pointer',
                        '&:hover': {
                          color: '#fff'
                        }
                      }} onClick={() => deletePlan(i)}>
                        {plan}
                        <CloseOutlined className={'ml-2'} />
                      </div>
                    ))}
                  </div>
                ) : null}
                <Textarea className={'w-100 p-4 default'} placeholder={'Чему научится студент'} onChange={(e) => setLongDescription(e.currentTarget.value)} value={longDescription} />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className={'mb-3'}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span style={{
                fontSize: 18,
                lineHeight: '28px',
                color: Colors.TextDimBlue,
                fontWeight: 'bolder'
              }}>Изменить/добавить цену курса</span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="p-4 d-flex align-items-center" style={{ gap: 20 }}>
                <label className={'position-relative d-flex align-items-center'} style={{ flex: 1 }}>
                  <span style={{
                    color: Colors.TextDimBlue,
                    position: 'absolute',
                    left: 20,
                  }}>Цена</span>
                  <Input
                    disabled={loading.status}
                    value={price.toString()}
                    onChange={(e) => setPrice(Number(e.currentTarget.value))}
                    type={'number'}
                    className={'w-100 text-right m-0'}
                    style={{ color: Colors.YellowBG, paddingRight: 60 }}
                  />
                  <span style={{
                    color: Colors.TextDimBlue,
                    position: 'absolute',
                    right: 20,
                  }}>KZT</span>
                </label>

                <label className={'position-relative d-flex align-items-center'} style={{ flex: 1 }}>
                  <span style={{
                    color: Colors.TextDimBlue,
                    position: 'absolute',
                    left: 20,
                  }}>Скидка</span>
                  <Input
                    disabled={loading.status}
                    value={discount.toString()}
                    onChange={(e) => setDiscount(Number(e.currentTarget.value))}
                    type={'number'}
                    className={'w-100 text-right m-0'}
                    style={{ color: Colors.YellowBG, paddingRight: 60 }}
                  />
                  <span style={{
                    color: Colors.TextDimBlue,
                    position: 'absolute',
                    right: 20,
                  }}>%</span>
                </label>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className={'mb-3'}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span style={{
                fontSize: 18,
                lineHeight: '28px',
                color: Colors.TextDimBlue,
                fontWeight: 'bolder'
              }}>{handleEdit ? 'Главы' : 'Добавить главу'}</span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="p-4">
                <div className="d-flex">
                  <Input
                    disabled={loading.status}
                    value={chapterCurrent}
                    onChange={(e) => setChapterCurrent(e.currentTarget.value)}
                    className={'w-100 m-0'}
                    placeholder={'Добавить главу'}
                  />
                  <Button
                    style={{
                      width: 'auto',
                      fontSize: 16,
                      padding: '10px 20px',
                      marginLeft: 20
                    }}
                    onClick={() => {
                      setChapters(prev => [...prev, { name: chapterCurrent, lectures: [] }]);
                      setChapterCurrent('');
                      setChapterSelected(0);
                    }}
                  ><PlusOutlined/></Button>
                </div>

                <SortableList useDragHandle items={chapters} onSortEnd={onSortEnd()} />
              </div>
            </AccordionDetails>
          </Accordion>

          {chapters.length ? (
            <Accordion className={'mb-3'}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <span style={{
                fontSize: 18,
                lineHeight: '28px',
                color: Colors.TextDimBlue,
                fontWeight: 'bolder'
              }}>{handleEdit ? 'Лекции' : 'Добавить лекцию'}</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="p-4">
                  <Select
                    value={chapterSelected}
                    onChange={(newState) => setChapterSelected(newState)}
                    css={{
                      width: '100%',
                      '& .ant-select-selector': {
                        padding: '12px 23px!important',
                        height: 'auto!important',
                        border: `1px solid ${Colors.Border}!important`,
                        fontSize: '16px!important',
                      },
                      '& .ant-select-selection-item': {
                        color: Colors.Placeholder
                      },
                    }}
                    className={'mb-3 text-left'}
                  >
                    {chapters.map((n, i) => (
                      <Select.Option value={i} key={i}>{n?.name}</Select.Option>
                    ))}
                  </Select>
                  <div className="d-flex">

                    <Input
                      disabled={loading.status}
                      value={lectureCurrent.name}
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        setLectureCurrent(prev => ({ ...prev, name: val }));
                      }}
                      className={'w-100 m-0 mr-3'}
                      placeholder={'Название новой лекции'}
                    />
                    <Input
                      disabled={loading.status}
                      value={lectureCurrent.videofile}
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        setLectureCurrent(prev => ({ ...prev, videofile: val }))
                      }}
                      className={'w-100 m-0'}
                      placeholder={'Ссылка на видео лекции'}
                    />
                    <Button
                      style={{
                        width: 'auto',
                        fontSize: 16,
                        padding: '10px 20px',
                        marginLeft: 20
                      }}
                      onClick={() => {
                        setChapters(prev => prev.map((n, i) => {
                          if (i === chapterSelected) {
                            return ({ ...n, lectures: [...n.lectures, lectureCurrent] });
                          } else {
                            return n;
                          }
                        }));
                        setLectureCurrent({
                          name: '',
                          videofile: ''
                        });
                      }}
                    ><PlusOutlined/></Button>
                  </div>
                  <SortableList useDragHandle items={chapters[chapterSelected]?.lectures} onSortEnd={onSortEnd(chapterSelected)} idChapter={chapterSelected} />
                </div>
              </AccordionDetails>
            </Accordion>
          ) : null}


          <Accordion className={'mb-3'}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span style={{
                fontSize: 18,
                lineHeight: '28px',
                color: Colors.TextDimBlue,
                fontWeight: 'bolder'
              }}>{handleEdit ? 'Тесты' : 'Добавить тест'}</span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="p-4">
                {tests.length ? (
                  <div className={'mb-4'}>
                    <SortableTestList useDragHandle items={tests} onSortEnd={onTestsSortEnd} />
                  </div>
                ) : null}
                <Button
                  style={{
                    fontSize: 16,
                    padding: '13px 15px'
                  }}
                  onClick={() => setTests(prev => [...prev, { content: '', answers: [] }])}
                >Добавить вопрос</Button>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <label className="d-flex align-items-center">
          <input type={'checkbox'} checked={landing} onChange={(e) => setLanding(e.currentTarget.checked)} />
          <span className={'d-block ml-3'}>Показывать на главной странице</span>
        </label>
        <label className="d-flex align-items-center">
          <input type={'checkbox'} checked={status} onChange={(e) => setStatus(e.currentTarget.checked)} />
          <span className={'d-block ml-3'}>Активировать курс</span>
        </label>
        <Button
          className={'mt-4'}
          style={{
            fontSize: 16,
            padding: '13px 15px'
          }}
          disabled={!(title?.length && shortDescription?.length && longDescription?.length && !loading?.status)}
          onClick={handleClick}
        >Сохранить</Button>
      </Container>



      <Modal
        visible={avatarModal}
        onCancel={() => setAvatarModal(false)}
        footer={null}
      >
        <Typography.Title level={4}>Редактировать фотографию</Typography.Title>
        <label style={{
          position: 'relative',
          borderRadius: 6,
          overflow: 'hidden',
          display: 'block',
          cursor: 'pointer',
        }}>
          <input type={'file'} hidden disabled={files ? files.length > 0 : false} onChange={(e) => setFiles(e.currentTarget.files)} accept="image/png, image/gif, image/jpeg"/>
          <img src={getImage(item?.title_image)} alt={'avatar'} className={'d-block'} style={{ width: '100%' }} />
          {!files?.length ? (
            <div style={{
              background: hexToRgba(Colors.Placeholder, .8),
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              padding: 50,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            }}>
              <span
                style={{
                  fontSize: 17,
                  padding: '10px 20px',
                  display: 'inline-block',
                  width: 'auto',
                  background: Colors.YellowBG,
                  borderRadius: 10,
                  fontWeight: 'bold'
                }}
              >Загрузить</span>
            </div>
          ) : null}
        </label>
      </Modal>
    </div>
  );
};
