import React, {
  FC,
  HTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useAtom } from '@reatom/react';
import { loadingAtom } from './store/ui/RootTemplate/atoms';
import { Header } from './components/Header';
import { RouteType } from './routes';
import { Footer } from './components/Footer';
import { userAtom } from './store/modules/user';
import { Preloader } from './components/Preloader';

type TemplateType = HTMLAttributes<HTMLDivElement> & { routesList: ReadonlyArray<RouteType> };

export const Template: FC<TemplateType> = (props: TemplateType) => {

  const [loading, { set: setLoading }] = useAtom(loadingAtom);
  const [user, { get: getUser }] = useAtom(userAtom);
  const [showMenu, setShowMenu] = useState(false);

  const { pathname } = useLocation();

  const noTemplatePaths = (props.routesList.filter(n => n.noTemplate).map(m => m.path));

  useEffect(() => {
    if (localStorage) {
      if (localStorage.getItem('token') && !user.loaded) {
        getUser();
      }
    }
  }, [getUser, user.loaded]);

  useEffect(() => {
    setLoading(user.loading);
  }, [setLoading, user.loading]);

  return (
    <div style={{
      ...(showMenu ? {
        height: '100vh',
        overflow: 'hidden'
      } : {}),
      ...{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }
    }}>
      {!noTemplatePaths.includes(pathname) || noTemplatePaths.includes(`${pathname}/`) ? (
        <Header showMenu={showMenu} setShowMenu={setShowMenu} />
      ) : null}

      <div className={'col p-0'}>
        {props.children}
      </div>

      {!noTemplatePaths.includes(pathname) || noTemplatePaths.includes(`${pathname}/`) ? (
        <Footer />
      ) : null}

      {(loading.status ? loading.status : loading.show) ? (
        <Preloader style={{
          opacity: (loading.status ? loading.show : loading.status) ? 1 : 0,
          ...((loading.status ? loading.show : loading.status) ? {
            transform: 'translateY(0)'
          } : {}),
        }} />
      ) : null}
    </div>
  );
};
