import React, { FC, useState } from 'react';
import {Props} from "./props";
import { Colors } from '../../core';
import { Container } from '../../components/Container';

import { CoursePriceCard } from '../../components/CoursePriceCard';
import {
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { CloseCircleOutlined, FileOutlined, LockFilled } from '@ant-design/icons';
import { ExpandMore, PlayArrowOutlined } from '@material-ui/icons';
import { Modal, Skeleton, Tooltip, Typography } from 'antd';
import { Accordion } from '../../components/Accordion';
import { Media } from '../../components/Media';
import classNames from 'classnames';
import { Button } from '../../components/Button';

export const Course: FC<Props> = ({ item, handlePurchase, purchasing, handleCancel, redirectUrl, ...rest }: Props) => {
  const { course, purchased } = item;
  const [modal, setModal] = useState(false);
  const [lecture, setLecture] = useState<any>(null);

  return purchasing ? (
	<div 
		style={{ 
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
			height: '100%',
			paddingTop: 235,
			paddingBottom: 120,
		}}
		{...rest}
	>
		<Container size={'600px'}>
			<div className="text-center">
				<Typography.Title level={2} className={'mb-2'}>
					Ожидание оплаты...
				</Typography.Title>

				<Typography.Paragraph className={'mb-5 mt-0'}>
					Если ссылка на оплату не открылась автоматический, нажмите&nbsp;
					<a 
						href={redirectUrl || ''} 
						target={'_blank'} 
						rel="noreferrer" 
						style={{ color: Colors.Primary }}
					>
						сюда
					</a>
				</Typography.Paragraph>

				<Button
					style={{
					fontSize: 16,
					width: 'auto',
					margin: 'auto',
					padding: '10px 25px'
					}}
					onClick={handleCancel}
				>
					Отмена
				</Button>
			</div>
		</Container>
	</div>
  ) : (
    <Media>
      {(matches: any) => (
        <div {...rest}>
          <section
            style={{
              paddingTop: matches.large ? 200 : 40,
              paddingBottom: matches.large ? 120 : 30,
              background: Colors.TextDimBlue
            }}
          >
            <Container className={classNames({ 'd-flex': matches.large }, 'justify-content-between')}>
              <div style={{
                maxWidth: matches.large ? 670 : '100%',
                width: '100%'
              }}>
                <h1 style={{
                  margin: 0,
				  marginBottom: 24,
                  fontSize: 50,
                  lineHeight: '57px',
                  color: Colors.YellowBG
                }}>{course?.title || <Skeleton.Input style={{ width: 400 }} />}</h1>
                <p style={{
                  fontSize: 17,
                  lineHeight: '27px',
                  color: '#fff',
                  margin: 0,
                  maxWidth: 500,
                }}>{course?.short_description || <Skeleton paragraph={{ rows: 3 }} active />}</p>
              </div>
              <CoursePriceCard
                course={course}
                purchased={purchased}
                handlePurchase={handlePurchase}
                style={{
                  position: matches.large ? 'absolute' : 'initial',
                  top: 0,
                  right: 25,
                  zIndex: 9,
                  ...(!matches.large ? {
                    maxWidth: '100%'
                  } : {})
                }}
              />
            </Container>
          </section>
          <Container className={'d-flex justify-content-between pb-5'}>
            <div style={{ maxWidth: matches.large ? 670 : '100%', width: '100%' }} className={'pt-5'}>
              <span style={{
                fontSize: 24,
                margin: 0,
                backgroundColor: Colors.YellowBG,
                padding: '7px 20px',
                fontWeight: 'bold',
                display: 'inline-block'
              }}>Чему вы научитесь</span>
              <div className={'mt-4'}  style={{
                border: `1px solid ${Colors.TextDimBlue}`,
                borderRadius: 6,
                padding: 15,
                marginBottom: 70
              }}>
                <p
                  className={'m-0'}
                  style={{
                    fontSize: 16,
                    lineHeight: 1.5
                  }}
                >{course?.long_description || <Skeleton paragraph={{ rows: 6 }} active />}</p>
              </div>

              <span style={{
                fontSize: 24,
                margin: 0,
                fontWeight: 'bold',
                display: 'inline-block'
              }}>Материалы курса</span>
              <p className={'mb-5'} style={{ fontSize: 14, color: 'grey' }}>Общая продолжительность 1ч</p>
              {course?.course_chapters.map((n: any, i: number) => (
                <Accordion key={i} className={'mb-3'}>
                  <AccordionSummary
                    expandIcon={<ExpandMore style={{ transform: 'translateY(-2px)' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className={classNames('d-flex', 'w-100', { 'flex-column': !matches.large, 'justify-content-between': matches.large })}>
                      <span style={{
                        fontSize: 22,
                        lineHeight: '28px',
                        color: Colors.TextDimBlue,
                        fontWeight: 'bolder',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        {n.name || <Skeleton.Input style={{ width: 400 }} />}
                        {!purchased && i !== 0 ? (
                          <Tooltip title={"Купите курс для доступа"}>
                            <div className='ml-3'>
                              <img src={'/images/lock.svg'} className='d-block' />
                            </div>
                          </Tooltip>
                        ) : null}
                      </span>
                      <span style={{ color: 'grey', fontSize: 16, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', paddingLeft: 15 }}>{n.lectures?.length} лекции • {n.lectures?.length} мин</span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                      {purchased || i === 0 ? (
                        n.lectures.map((lecture: any, lectureIndex: number) => (
                          <div
                            key={lectureIndex}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 20,
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setModal(true);
                              setLecture(lecture);
                            }}
                          >
                            <div style={{
                              background: '#fff',
                              color: Colors.Primary,
                              width: 35,
                              height: 35,
                              textAlign: 'center',
                              justifyContent: 'space-around',
                              display: 'flex',
                              borderRadius: 100,
                              alignItems: 'center'
                            }}>{lecture.videofile ? <PlayArrowOutlined className={'d-flex align-items-center'} /> : <FileOutlined className={'d-flex align-items-center'} />}</div>
                            <span className={'d-block mr-auto'}>{lecture.name}</span>
                            {lecture.videofile ? <span style={{ fontSize: 14, color: Colors.Placeholder }}>03:27</span> : null}
                          </div>
                        ))
                      ) : (
                        <div style={{
                          fontSize: 16,
                          textAlign: 'center',
                          padding: 15,
                          color: Colors.Placeholder,
                        }}>
                          <LockFilled className={'mr-3'} />
                          <span>Купите курс для доступа</span>
                        </div>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )) || <Skeleton paragraph={{ rows: 6 }} />}

            </div>
          </Container>

          <Modal
            title={<span style={{ fontWeight: 'bolder', fontSize: 24, color: Colors.TextDimBlue }}>{lecture?.name}</span>}
            visible={modal}
            closable
            footer={null}
            onCancel={() => {
              setModal(false);
              setLecture(null);
            }}
            closeIcon={<CloseCircleOutlined style={{ color: Colors.Primary, fontSize: 20 }} />}
            width={!matches.small ? 1000 : 400}
            style={{ borderRadius: 10 }}
          >
            <p className={'mb-4'} style={{ lineHeight: 1.4, color: Colors.TextDimBlue }}>{lecture?.description}</p>
            {lecture?.videofile ? (
              <iframe src={lecture.videofile} style={{ width: '100%', border: 'none', height: 500 }} title={lecture?.name} />
            ) : null}
          </Modal>
        </div>
      )}
    </Media>
  );
}
