import React, { FC } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Props } from './props';
import hexToRgba from 'hex-to-rgba';
import { Colors } from '../../core';

export const Preloader: FC<Props> = ({ style, ...rest }: Props) => {
  return (
    <div
      style={{
        color: '#fff',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999999,
        paddingTop: 130,
        textAlign: 'center',
        transform: 'translateY(-150px)',
        transition: 'opacity 0.3s, transform 0.3s',
        ...style,
      }}
      {...rest}
    >
      <Spin
        style={{
          borderRadius: 100,
          padding: 10,
          background: Colors.TextDimBlue,
          boxShadow: '0 7px 7px rgba(0,0,0,.15)'
        }}
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 30,
              color: Colors.YellowBG
            }}
          />
        }
      />
    </div>
  );
}
