import React, {FC} from "react";
import {Props} from "./props";

export const Error: FC<Props> = ({ message, style, success, ...rest }: Props) => {
    return (
        <div style={{
            background: success ? 'rgb(190 255 210 / 85%)' : 'rgba(255, 202, 190, 0.85)',
            padding: 15,
            fontSize: 14,
            lineHeight: 1.5,
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            gap: 15,
            ...style
        }} {...rest}>
          {success ? null : (
            <div style={{ flex: '0 0 30px' }}>
              <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.1313 32.1515C24.4881 32.1515 31.2626 25.377 31.2626 17.0202C31.2626 8.66344 24.4881 1.88892 16.1313 1.88892C7.77452 1.88892 1 8.66344 1 17.0202C1 25.377 7.77452 32.1515 16.1313 32.1515Z" stroke="#FF4A4A" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.1311 10.9678V17.0203" stroke="#FF4A4A" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <circle cx="16.1316" cy="23.0727" r="1.51313" fill="#FF4A4A" fill-opacity="0.7"/>
              </svg>
            </div>
          )}
          

          <p style={{ fontSize: 13, margin: 0, color: 'rgba(0,0,0,.6)' }}>
            {message}
          </p>
        </div>
    );
}
