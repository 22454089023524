import { createAtom } from '@reatom/core';

const defaultState: { status: boolean, show: boolean, timeout: any } = {
  status: false,
  show: false,
  timeout: null
};

export const loadingAtom = createAtom(
  { set: (newState: boolean) => newState, _mutate: (newState: typeof defaultState) => newState, start: () => null, finish: () => null },
  ({ onAction, schedule, create }, state = defaultState) => {

    onAction('_mutate', (newState) => {
      state = newState;
    });

    onAction('set', (newState: boolean) => {
      if (state.timeout) {
        clearTimeout(state.timeout);
      }
      schedule((dispatch) => {
        if (newState) {
          dispatch(create('_mutate', { show: true, status: true, timeout: null }));
        } else {
          const tmpTimeout = setTimeout(() => {
            clearTimeout(state.timeout);
            dispatch(create('_mutate', { status: false, show: false, timeout: null }));
          }, 300);
          dispatch(create('_mutate', { show: true, status: false, timeout: tmpTimeout }));
        }
      });
    });

    return state;
  });
