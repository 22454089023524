import React, { FC, useEffect, useState } from 'react';
import { Container } from '../../components/Container';
import { Colors, getAvatar } from '../../core';
import { Card, message, Modal, Skeleton, Typography } from 'antd';
import { useAtom } from '@reatom/react';
import { userAtom } from '../../store/modules/user';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Media } from '../../components/Media';
import classNames from 'classnames';
import hexToRgba from 'hex-to-rgba';
import { usersService } from '../../service';

export const Template: FC = (props) => {
  const [user, { get: getUser }] = useAtom(userAtom);
  const router = useHistory();
  const location = useLocation();
  const [avatarModal, setAvatarModal] = useState(false);
  const [files, setFiles] = useState<FileList | null>(null);

  if (!localStorage?.getItem('token')) {
    router.push('/auth/signin');
  }

  const activeColor = (path: string) => location.pathname === path ? Colors.YellowBG : '#fff';

  useEffect(() => {
    if(files?.length) {
      const form = new FormData();
      form.append('image', files[0]);
      usersService.uploadAvatar(form)
        .then(async () => {
          getUser();
          await message.success('Фотография обновлена');
        })
        .catch(async () => {
          await message.error('Что-то не так');
        })
        .finally(() => {
          setFiles(null);
          setAvatarModal(false);
        });
    }
  }, [files, getUser]);

  return (
    <div className={'h-100'} style={{ background: '#f7f7f7' }}>
      <Media>
        {(matches: any) => (
          <>
            <header
              style={{
                background: Colors.YellowBG,
                padding: matches.large ? '150px 0' : '50px 0'
              }}
            >
              <Container className={'position-relative'}>
                {matches.large ? (
                  <span style={{
                    fontSize: 35,
                    color: '#fff',
                    fontWeight: 'bold'
                  }}>Мой профиль</span>
                ) : null}
                <Container className={classNames('position-relative', { 'position-absolute': matches.large, 'w-100': matches.large, 'p-0': !matches.large })} style={{ left: 0 }}>
                  <Card
                    style={{
                      boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)',
                      borderRadius: 8,
                      border: 'none',
                      marginTop: matches.large ? 100 : 0,
                      padding: matches.large ? 20 : 0,
                      width: '100%',
                    }}
                  >
                    <div className={classNames({ 'flex-wrap': !matches.large })} style={{ gap: 40, display: matches.large ? 'grid' : 'flex', gridTemplateColumns: '120px 1fr 1fr' }}>
                      <div style={{ position: 'relative', flex: matches.large ? '0 0 120px' : '0 0 100%', textAlign: matches.large ? 'left' : 'center' }}>
                        <img
                          src={getAvatar(user.data?.photo)}
                          alt={user.data?.name}
                          onClick={() => setAvatarModal(true)}
                          css={{
                            height: 120,
                            width: 120,
                            borderRadius: 100,
                            background: Colors.TextDimBlue,
                            position: matches.large ? 'absolute' : 'initial',
                            bottom: 0,
                            cursor: 'pointer',
                            transition: 'transform 0.2s, filter 0.2s',
                            '&:hover': {
                              transform: 'scale(1.05)',
                              filter: 'hue-rotate(45deg)'
                            }
                          }}
                        />
                      </div>
                      <div style={{ flex: '0 0 100%', alignSelf: 'center', ...(!matches.large ? { textAlign: 'center' } : {}) }}>
                        <h1 className={'m-0'} style={{ fontSize: 28, lineHeight: 1.3, textTransform: 'capitalize' }}>{user.loading ? <Skeleton.Input style={{ width: 300 }} /> : `${user.data?.name} ${user.data?.second_name}`}</h1>
                      </div>
                      <div
                        className={classNames('d-flex', { 'flex-column': matches.small })}
                        style={{
                          gap: 5,
                          flex: '0 0 100%',
                          ...(!matches.large ? {
                            background: '#f7f7f7',
                            padding: 5,
                            borderRadius: 10,
                          } : {})
                        }}
                      >
                        <Button onClick={() => router.push("/profile/courses")} style={{ fontSize: 16, border: 'none', fontWeight: 500, padding: '10px 20px', background: activeColor("/profile/courses") }}>Курсы</Button>
                        <Button onClick={() => router.push("/profile/certificates")} style={{ fontSize: 16, border: 'none', fontWeight: 500, padding: '10px 20px', background: activeColor("/profile/certificates") }}>Сертификаты</Button>
                        {/*<Button onClick={() => router.push("/profile/tests")} style={{ fontSize: 16, border: 'none', fontWeight: 500, padding: '10px 20px', background: activeColor("/profile/tests") }}>Тесты</Button>*/}
                      </div>
                    </div>
                  </Card>
                </Container>
              </Container>
            </header>
            <div style={{
              ...(!matches.large ? {
                padding: '30px 0'
              } : {
                paddingTop: 150,
                paddingBottom: 200
              })
            }}>
              <Container {...(matches.large ? { size: '970px' } : {})} >
                {props.children}
              </Container>
            </div>



            <Modal
              visible={avatarModal}
              onCancel={() => setAvatarModal(false)}
              footer={null}
            >
              <Typography.Title level={4}>Редактировать фотографию</Typography.Title>
              <label style={{
                position: 'relative',
                borderRadius: 6,
                overflow: 'hidden',
                display: 'block',
                cursor: 'pointer',
              }}>
                <input type={'file'} hidden disabled={files ? files.length > 0 : false} onChange={(e) => setFiles(e.currentTarget.files)} accept="image/png, image/gif, image/jpeg"/>
                <img src={getAvatar(user.data?.photo)} alt={'avatar'} className={'d-block'} style={{ width: '100%' }} />
                {!files?.length ? (
                  <div style={{
                    background: hexToRgba(Colors.Placeholder, .8),
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    padding: 50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                  <span
                    style={{
                      fontSize: 17,
                      padding: '10px 20px',
                      display: 'inline-block',
                      width: 'auto',
                      background: Colors.YellowBG,
                      borderRadius: 10,
                      fontWeight: 'bold'
                    }}
                  >Загрузить</span>
                  </div>
                ) : null}
              </label>
            </Modal>
          </>
        )}
      </Media>
    </div>
  );
}
