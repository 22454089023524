import { FC, HTMLAttributes } from 'react';
import { Container } from '../../components/Container';

export const Policy: FC = () => {
  return (
    <div style={{ padding: '160px 0' }}>
      <Container>
        <iframe
          src={'/policy.pdf'}
          style={{
            width: '100%',
            height: 1000,
            border: 'none'
          }}
        />
      </Container>
    </div>
  );
}
