import React, { FC, useEffect } from 'react';

import { useAtom } from '@reatom/react';

import { Users } from '../../../pages/Admin/Users';

import { allUsersAtom } from '../../../store/modules/user';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';

import {
	useParams,
	useHistory,
	useLocation,
} from 'react-router-dom';

export const UsersContainer: FC = () => {
	const [, { set: setLoading }] = useAtom(loadingAtom);
	const [users, { get: getUsers }] = useAtom(allUsersAtom);

	const router = useHistory();

	const params = useParams<{ page?: any }>();
	const location = useLocation();

	const query = new URLSearchParams(location.search).get('f');

	useEffect(() => {
		getUsers(params.page || 1, query);
	}, [getUsers, params.page, query]);

	useEffect(() => {
		setLoading(users.loading);
	}, [users.loading, setLoading]);

	const handleSearch = (f: string) => {
		if (f.length >= 3) {
			router.push(`/admin/users/${params.page || 1}?f=${f}`);
		} else {
			getUsers(params.page);
		}
	};

	return (
		<Users
			handleSearch={handleSearch}
			users={users.data}
			perPage={Number(users.perPage)}
			lastPage={Number(users.lastPage)}
			currentPage={Number(params.page || 1)}
			query={query}
		/>
	);
};
