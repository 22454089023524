import React from 'react';

import {
  BrowserRouter as Router
} from 'react-router-dom';
import { getRouterSwitch } from './routes';
import { createStore } from '@reatom/core';
import { reatomContext } from '@reatom/react';
import { ConfigProvider, message } from 'antd';
import { ErrorBoundary } from './containers';

export const App = () => {
  const RouterSwitch = getRouterSwitch();
  const store = createStore();

  message.config({
    top: 10
  });

  return (
    <ErrorBoundary>
      <reatomContext.Provider value={store}>
        <ConfigProvider direction="ltr">
          <Router>
            {RouterSwitch}
          </Router>
        </ConfigProvider>
      </reatomContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
