import styled from '@emotion/styled';
import { Colors } from '../../../../core';

export const NavigationLink = styled.button({
  background: 'transparent',
  border: 'none',
  fontWeight: 500,
  cursor: 'pointer',
  transition: 'color 0.2s',
  '&:hover': {
    color: Colors.Primary
  }
});
