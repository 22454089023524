import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../store/ui/RootTemplate/atoms';
import { Course } from '../../pages';
import { courseAtom } from '../../store/modules/courses';
import { coursesService } from '../../service';
import { message } from 'antd';

export const CourseContainer: FC = () => {

  const [, { set: setLoading }] = useAtom(loadingAtom);
  const [course, { get: getCourse }] = useAtom(courseAtom);
  const [purchasing, setPurchasing] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  const { id } = useParams<{ id: any }>();

  useEffect(() => {
    getCourse(id);
  }, [getCourse, id]);

  useEffect(() => {
    setLoading(course.loading);
  }, [course.loading, setLoading]);

  const handlePurchase = useCallback(() => {
    setLoading(true);
    coursesService.purchase(id)
      .then(res => {
        setPurchasing(true);
        const redirect_url = res.data.redirect_url;
        setRedirectUrl(redirect_url);
        window?.open(redirect_url, '_blank')?.focus();
      })
      .catch(async () => {
        await message.error("Этот курс уже приобретен");
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  const handleCancel = useCallback(() => {
    setPurchasing(false);
    setRedirectUrl('');
  }, []);

  return <Course item={course.data} handlePurchase={handlePurchase} purchasing={purchasing} handleCancel={handleCancel} redirectUrl={redirectUrl} />;
}
