import React, { Component, ErrorInfo } from "react";
import { Props } from './props';
import { Typography } from 'antd';

import { Container } from '../../../components/Container';

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          <Container size={'600px'}>
            <div className="text-center">
              <Typography.Title level={2} className={'mb-2'}>Ошибка 500</Typography.Title>
              <Typography.Paragraph className={'mb-5 mt-0'}>Произошла непредвиденная ошибка. Попробуйте позже</Typography.Paragraph>
            </div>
          </Container>
        </div>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
