import React, { FC, useCallback, useEffect, useState } from 'react';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';
import { coursesService, testsService } from '../../../service';
import { message } from 'antd';
import { CoursePage } from '../../../pages/Admin/CoursePage';
import { useHistory, useParams } from 'react-router-dom';
import { adminCourseAtom } from '../../../store/modules/courses';

export const EditCourseContainer: FC = () => {
  const [loading, setLoading] = useState(false);
  const router = useHistory();
  const { id } = useParams<{ id: any }>();

  const [course, { get: getCourse }] = useAtom(adminCourseAtom);
  const [tests, setTests] = useState([]);

  const fetchCourses = useCallback(() => {
    if (id) {
      getCourse(id);
      setLoading(true);
      testsService.get(id)
        .then((res) => {
          setTests(res.data?.questions ?? []);
        })
        .finally(() => {
          setLoading(false);
        });
    } else router.push('/admin/courses');
  }, [id, setTests, getCourse, router]);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  const handleEdit = (data: any) => {
    setLoading(true);
    coursesService.update(id, data)
      .then(async (res) => {
        fetchCourses();
        message.success('Успешно!');
      })
      .catch(async () => {
        await message.error('Что-то пошло не так. Свяжитесь с администратором');
      })
      .finally(() => setLoading(false));
  };

  const handleUpload = (data: any) => {
    setLoading(true);
    coursesService.upload(id, data)
      .then(async () => {
        fetchCourses();
        await message.success('Обновлено');
      })
      .catch(async () => {
        await message.error('Произошла какая-то ошибка');
      })
      .finally(() => setLoading(false));
  }

  return course.loading || loading ? <div>Loading...</div> : course.loaded ? <CoursePage handleEdit={handleEdit} item={course.data?.course} handleUpload={handleUpload} questions={tests} /> : null;
}
