import { createAtom } from '@reatom/core';

export const activeness = createAtom(
  { set: (newState: boolean) => newState },
  ({ onAction }, state = false) => {

    onAction('set', (newState) => {
      state = newState;
    });

    return state;
  });
