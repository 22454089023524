import React, {FC, useState} from "react";
import {SignUp} from "../../pages";
import { authService } from '../../service';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../store/ui/RootTemplate/atoms';

export const SignUpContainer: FC = () => {
    const [error, setError] = useState<string | null>(null);
    const [, { set: setLoading }] = useAtom(loadingAtom);

    const handleSubmit = (data: any) => {
        setError(null);
        if (Object.entries(data).filter(([, value]) => value).length === Object.entries(data).length) {
          setLoading(true);
          authService.register({ ...data, second_name: data.lastName })
            .then((res) => {
              localStorage.setItem('token', res.data.access_token);
              window.location.replace('/');
            })
            .catch((e) => {
              setError('Пользователь с такой электронной почтой уже зарегистрирован');
            })
            .finally(() => setLoading(false));
        } else {
            setError('Заполните все обязательные поля');
        }
    }

    return (
        <SignUp handleSubmit={handleSubmit} error={error} />
    );
}
