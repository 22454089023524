import styled from "@emotion/styled";
import {Colors} from "../../core";

export const Button = styled.button`
  line-height: 1.5;
  background: ${Colors.YellowBG};
  border: 1px solid ${Colors.Primary};
  display: block;
  width: 100%;
  font-size: 24px;
  font-weight: bolder;
  border-radius: 6px;
  padding: 7px 10px;
  cursor: pointer;
  transition: background 0.2s;
  &:hover {
    background: ${Colors.Primary};
  }
  &:disabled {
    background: ${Colors.GreyText};
    border: none;
  }
`;
