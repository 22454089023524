import React, { FC } from 'react';
import { Props } from './props';
import { Main } from './sections/Main';
import { About } from './sections/About';
import { Courses } from './sections/Courses';
import { Benefits } from './sections/Benefits';
import { Form } from './sections/Form';

export const Home: FC<Props> = ({
	courses,
	benefits,
	searchResult,
	handleSearch,
	searchNotFound,
	searchLoading,
	handleFeedback,
	...rest
}: Props) => {

	return (
		<div {...rest}>
			<Main
				handleSearch={handleSearch}
				searchResult={searchResult}
				searchNotFound={searchNotFound}
				searchLoading={searchLoading}
			/>
			<About />
			<Courses items={courses || []} />
			<Benefits items={benefits || []} />
			<Form handleFeedback={handleFeedback} />
		</div>
	);
};
