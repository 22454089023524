import {createAtom} from "@reatom/core";
import { usersService } from '../../../service';

const defaultState = {
  loading: false,
  loaded: false,
  data: [],
  error: null,
};

export const cabinetAtom = createAtom(
  { get: () => null, clear: () => {}, _mutate: (newState: any) => newState },
  ({ onAction, create, schedule, get }, state: any = defaultState) => {

    onAction('_mutate', (newState) => (state = newState));
    onAction('clear', () => {
      schedule((dispatch => {
        dispatch(create('_mutate', null));
      }))
    });

    onAction('get', () => {
      schedule((dispatch) => {
        dispatch(create('_mutate', { ...defaultState, loading: true }));
        usersService.cabinet()
          .then(response => {
            dispatch(create('_mutate', { ...defaultState, loaded: true, data: response.data }));
          })
          .catch(e => {
            dispatch(create('_mutate', { ...defaultState, error: e }));
          });
      });
    });

    return state;
  });
