import {createAtom} from "@reatom/core";
import { coursesService } from '../../../service';

const defaultState = {
  loading: false,
  loaded: false,
  data: {},
  error: null,
};

export const courseAtom = createAtom(
  { get: (id: number) => id, clear: () => {}, _mutate: (newState: any) => newState },
  ({ onAction, create, schedule, get }, state: any = defaultState) => {

    onAction('_mutate', (newState) => (state = newState));
    onAction('clear', () => {
      schedule((dispatch => {
        dispatch(create('_mutate', null));
      }))
    });

    onAction('get', (id) => {
      schedule((dispatch) => {
        dispatch(create('_mutate', { ...defaultState, loading: true }));
        coursesService.getById(id)
          .then(response => {
            dispatch(create('_mutate', { ...state, loaded: true, data: response.data, loading: false }));
          })
          .catch(e => {
            dispatch(create('_mutate', { ...defaultState, error: e, loading: false }));
          });
      });
    });

    return state;
  });
