import React, { FC, useEffect, useState } from 'react';
import { Props } from './props';
import { Colors } from '../../../core';
import { Container } from '../../../components/Container';
import { Input } from '../../../components/Form/Input';
import { Select } from 'antd';
import { Button } from '../../../components/Button';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';

export const UserPage: FC<Props> = ({ handleAdd, handleUpdate, item, courses, ...rest }: Props) => {
  const [loading] = useAtom(loadingAtom);

  const [name, setName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState(0);
  const [coursesState, setCoursesState] = useState([]);

  const data = item ? {
    name,
    second_name: secondName,
    email,
    role,
    courses: coursesState
  } : {
    name,
    second_name: secondName,
    email,
    password,
    role,
    courses: coursesState
  };

  useEffect(() => {
    if (item) {
      setName(item.name);
      setSecondName(item.second_name);
      setEmail(item.email);
      setRole(item.role);
      setCoursesState(item.courses);
    }
  }, [item]);

  return (
    <div {...rest}>
      <Container style={{ paddingTop: 200, marginBottom: 100 }} size={'600px'}>
        <div className={'py-5 px-0 text-center'}>
            <span style={{
              display: 'block',
              flex: 1,
              fontSize: 24,
              fontWeight: 'bold'
            }}>{!item ? 'Добавить нового пользователя' : 'Редактировать данные пользователя'}</span>
        </div>

        <div style={{
          border: `1px solid #d7d7d7`,
          color: Colors.TextDimBlue,
          borderRadius: 8,
          background: Colors.PlaceholderBG,
        }}>
          <div className={'p-4'}>
            <Input disabled={loading.status} value={name} onChange={(e) => setName(e.currentTarget.value)} className={'w-100'} placeholder={'Имя'} />
            <Input disabled={loading.status} value={secondName} onChange={(e) => setSecondName(e.currentTarget.value)} className={'w-100'} placeholder={'Фамилия'} />
            <Input disabled={loading.status} value={email} onChange={(e) => setEmail(e.currentTarget.value)} className={'w-100'} placeholder={'Email'} />
            {item ? null : (
              <>
                <Input disabled={loading.status} value={password} onChange={(e) => setPassword(e.currentTarget.value)} className={'w-100'} placeholder={'Пароль'} />
                <Input disabled={loading.status} value={confirmPassword} onChange={(e) => setConfirmPassword(e.currentTarget.value)} className={'w-100'} placeholder={'Подтвердить пароль'} />
              </>
            )}
            <Select
              value={role}
              css={{
                width: '100%',
                '& .ant-select-selector': {
                  padding: '12px 23px!important',
                  height: 'auto!important',
                  border: `1px solid ${Colors.Border}!important`,
                  fontSize: '16px!important',
                },
                '& .ant-select-selection-item': {
                  color: Colors.Placeholder
                },
              }}
              onChange={(newState) => setRole(newState)}
              disabled={loading.status}
            >
              <Select.Option value={0} style={{ padding: '15px 20px', fontSize: 16 }}>Пользователь</Select.Option>
              <Select.Option value={1} style={{ padding: '15px 20px', fontSize: 16 }}>Администратор</Select.Option>
            </Select>

            <Select
              className='mt-4'
              mode='multiple'
              value={coursesState}
              placeholder='Курсы'
              css={{
                width: '100%',
                '& .ant-select-selector': {
                  padding: '12px 23px!important',
                  height: 'auto!important',
                  border: `1px solid ${Colors.Border}!important`,
                  fontSize: '16px!important',
                },
                '& .ant-select-selection-placeholder': {
                  color: Colors.Placeholder,
                  left: 23
                },
              }}
              onChange={(newState) => setCoursesState(newState)}
              disabled={loading.status || item}
            >
              {courses?.map((n) => (
                <Select.Option value={n.id} style={{ padding: '15px 20px', fontSize: 16 }}>{n.title}</Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <Button
          className={'mt-4'}
          style={{
            fontSize: 16,
            padding: '13px 15px'
          }}
          disabled={item ? false : !(name.length && secondName.length && email.length && password.length && (password === confirmPassword) && !loading.status)}
          onClick={() => {
            if (item && handleUpdate) {
              handleUpdate(data);
            }
            else if (handleAdd) {
              handleAdd(data);
            }
          }}
        >Сохранить</Button>
      </Container>
    </div>
  );
};
