import { Container } from '../../../components/Container';
import { Typography } from 'antd';
import { Button } from '../../../components/Button';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CheckCircleFilled } from '@ant-design/icons';

export const PaymentSuccess = () => {
  const router = useHistory();
  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
      <Container size={'600px'}>
        <div className="text-center">
          <Typography.Title
            level={2}
            className={'mb-2'}
          >
            <CheckCircleFilled className={'mr-3'} style={{ color: '#52c41a' }} />
            <span>Оплата прошла успешно</span>
          </Typography.Title>
          <Typography.Paragraph className={'mb-5 mt-0'}>Теперь вы имеете доступ к оплаченному курсу</Typography.Paragraph>
          <Button
            style={{
              fontSize: 13,
              width: 'auto',
              margin: 'auto',
              padding: '10px 25px'
            }}
            onClick={() => router.push('/courses')}
          >Обратно к курсам</Button>
        </div>
      </Container>
    </div>
  );
}
