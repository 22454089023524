import React, {FC, HTMLAttributes} from "react";
import {Colors} from "../../../core";
import {Container} from "../../../components/Container";
import {BenefitCard} from "../../../components/BenefitCard";
import { Media } from '../../../components/Media';

type Props = HTMLAttributes<HTMLDivElement> & {
    readonly items: ReadonlyArray<any>;
}

export const Benefits: FC<Props> = ({ items, style, ...rest }: Props) => {
    return (
        <div style={{
            padding: '50px 0',
            textAlign: 'center',
            ...style
        }} {...rest}>
          <Media>
            {(matches: any) => (
              <Container>
                <h1 style={{
                  fontSize: matches.large ? 50 : 30,
                  color: Colors.Primary,
                  margin: 0
                }}>Преимущества</h1>
                <p style={{
                  fontSize: matches.large ? 20 : 15,
                  margin: 0,
                  color: Colors.GreyText,
                }}>Почему стоит довериться нам !</p>
                <div style={{
                  display: 'grid',
                  gridTemplateColumns: matches.small ? '1fr' : (matches.medium ? '1fr 1fr' : Array.from(Array(items?.length || 0).keys()).map(() => '1fr').join(' ')),
                  gap: matches.large ? 30 : '70px 40px',
                  marginTop: matches.large ? 65 : 85
                }}>
                  {items.map((n, i) => (
                    <BenefitCard item={n} key={i} />
                  ))}
                </div>
              </Container>
            )}
          </Media>
        </div>
    );
}
