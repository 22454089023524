import React, {FC} from "react";
import {Colors} from '../../../core';
import {Container} from "../../../components/Container";
import { useHistory } from 'react-router-dom';
import { Media } from '../../../components/Media';
import { Button } from '../../../components/Button';

export const About: FC = () => {
  const router = useHistory();
  return (
    <div style={{
      background: `rgba(19, 17, 53, .9)`,
      color: '#fff',
      padding: '50px 0',
      textAlign: 'center'
    }}>
      <Media>
        {(matches: any) => (
          <Container>
            <h1 style={{
              fontSize: matches.large ? 50 : 40,
              color: Colors.Primary,
              margin: 0
            }}>О нас</h1>
            <p style={{
              maxWidth: 760,
              textAlign: 'center',
              margin: 'auto',
              fontSize: matches.large ? 24 : 18,
              lineHeight: 1.5,
              marginTop: matches.large ? 15 : 0,
              marginBottom: 35
            }}>Наш учебный центр поможет вам приобрести необходимые профессиональные навыки, повысить квалификацию и получить документы, установленного образца в удобное для вас время.</p>
            <Button
              style={{
                display: 'inline-block',
                padding: matches.large ? '15px 25px' : '10px 25px',
                color: Colors.TextDimBlue,
                borderRadius: 4,
                fontSize: 16,
                fontWeight: 500,
                border: 'none',
                cursor: 'pointer',
                width: 'auto'
              }}
              onClick={() => router.push('/about')}
            >Подробнее</Button>
          </Container>
        )}
      </Media>
    </div>
  );
}
