import React, {FC} from "react";
import {Props} from "./props";
import {Colors} from "../../core";
import { Container } from '../../components/Container';
import hexToRgba from 'hex-to-rgba';
import { Media } from '../../components/Media';
import classNames from 'classnames';

export const About: FC<Props> = ({ ...rest }: Props) => {
    return (
        <div {...rest}>
          <Media>
            {(matches: any) => (
              <>
                {matches.large ? (
                  <section
                    style={{
                      padding: matches.large ? '250px 0' : '100px 0',
                      textAlign: 'center',
                      backgroundImage: `linear-gradient(${hexToRgba(Colors.TextDimBlue, 0.5)}, ${hexToRgba(Colors.TextDimBlue, 0.5)}), url(/images/hero.png)`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  >
                  <span style={{
                    fontSize: 50,
                    margin: 0,
                    backgroundColor: Colors.YellowBG,
                    padding: '3px 20px',
                    borderRadius: 6,
                    fontWeight: 'bold',
                    color: '#fff'
                  }}>О нас</span>
                  </section>
                ) : null}
                <Container>
                  <div className={classNames('d-flex', 'align-items-start', { 'flex-column-reverse': !matches.large })} style={{ gap: 30, padding: matches.large ? '120px 0' : '25px 0', color: Colors.TextDimBlue }}>
                    <div style={{ flex: matches.large ? '0 0 400px' : 1 }}>
                      <img src="/images/hero2.png" alt="Proftime" style={{ width: '100%', display: 'block' }} />
                    </div>
                    <div>
                      <span style={{ color: Colors.YellowBG, fontSize: 14, textTransform: 'uppercase' }}>Учебный центр «PROFTIME»</span>
                      <h1 style={{
                        fontSize: 36,
                        fontWeight: 400,
                        marginTop: 15,
                        lineHeight: '48px',
                      }}>Учебный центр
                      </h1>
                      <p style={{
                        fontSize: 18,
                        lineHeight: '32px'
                      }}>«PROFTIME»
                      компания, специализирующаяся на
                      услугах по подготовке руководителей, специалистов и работников
                      предприятий в области Безопасности и охраны труда, промышленной
                      безопасности, пожарному-техническому минимуму, безопасному ведению
                      работ на высоте и верхолазных работах, а также в сфере профессионального
                      образования и повышении квалификации по рабочим специальностям.
                      На сайте нашего учебного центра вы найдете гибкий и доступный
                      вариант обучения.
                      Мы предлагаем полноценные курсы, которые включают видеолекции и
                      презентации.
                      Слушатели курсов могут приобрести курс, пройти подготовку в удобное
                      для них время и сдать экзамен (тесты) непосредственно на сайте.
                      Доступ к курсам и материалам на сайте неограничен по времени.
                      По окончании курса, при условии успешной сдачи заключительного
                      экзамена (тестирования), слушателю курсов выдаются документы
                      установленного образца.
                      Наш учебный центр предлагает различные программы обучения в
                      современном формате, как «онлайн» с доступом из любой точки и в удобное
                      для вас время, так и «офлайн».
                      Для обеспечения профессиональной и безопасной работы на
                      производстве, строительстве и в процессе эксплуатации оборудования
                      необходимо проходить подготовку, как руководителям, так и работникам,
                      согласно требованиям, правил и сроков, указанных в нормативно-правовых
                      документах.
                      В этом вам помогут квалифицированный состав преподавателей с
                      большим опытом работы и специалисты нашего учебного центра.
                      Мы гарантируем качественную организацию учебного процесса и
                      оперативное решение возникающих вопросов.
                      </p>
                    </div>
                  </div>
                </Container>
              </>
            )}
          </Media>
        </div>
    );
}
