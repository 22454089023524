import React, { FC } from 'react';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';
import { coursesService } from '../../../service';
import { message } from 'antd';
import { CoursePage } from '../../../pages/Admin/CoursePage';
import { useHistory } from 'react-router-dom';

export const AddCourseContainer: FC = () => {
  const [, { set: setLoading }] = useAtom(loadingAtom);
  const router = useHistory();
  const handleAdd = (data: any) => {
    setLoading(true);
    coursesService.create(data)
      .then(async (res) => {
        message.success('Успешно!');
        router.push(`/admin/course/${res.data.id}`);
      })
      .catch(async () => {
        await message.error('Что-то пошло не так. Свяжитесь с администратором');
      })
      .finally(() => setLoading(false));
  };

  return <CoursePage handleAdd={handleAdd} />;
}
