import {instance} from "../core";

export const authService = {
  get: () => instance.get('user'),
  signIn: (user: any) => instance.post('login', user).then(res => res.data),
  signUp: (user: any) => instance.post('user', user),
  register: (user: any) => instance.post('register', user),
  recover: (email: string) => instance.post('user/password/reset', { email }),
  recoverApprove: (data: any) => instance.post('user/password/reset/submit', data),
}
