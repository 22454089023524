import { generateRouteTree } from './generateRouteTree';
import { Redirect, Route, Switch } from 'react-router-dom';
import React, { Fragment } from 'react';
import { Routes } from '../index';
import { Template } from '../../template';

export const getRouterSwitch = () => {
  const routesList = generateRouteTree(Routes);
  return (
    <Switch>
      {routesList.map((n, i) => {
        const ModuleTemplate = n.template || Fragment;
        return (
          <Route
            path={n.path}
            key={i}
            exact
            render={() => n.redirect ? (
              <Redirect to={n.redirect} />
            ) : (
              <Template routesList={routesList}>
                <ModuleTemplate>{React.createElement(n.component)}</ModuleTemplate>
              </Template>
            )}
          />
        );
      })}
    </Switch>
  )
};
