
import {Props} from "./props";
import styled from "@emotion/styled";
import {css} from "@emotion/react";

export const Container = styled.div<Props>`
  ${({ size }) => css`
    position: relative;
    margin: auto;
    padding: 0 25px;
    max-width: ${size ?? '1170px'};
  `}
`;
