import React, {FC, useEffect} from "react";
import {Courses} from "../../pages";
import {useAtom} from "@reatom/react";
import { publicCoursesAtom } from '../../store/modules/courses';
import { loadingAtom } from '../../store/ui/RootTemplate/atoms';

export const CoursesContainer: FC = () => {
  const [, { set: setLoading }] = useAtom(loadingAtom);
  const [courses, { get }] = useAtom(publicCoursesAtom);

  useEffect(() => {
    if(!courses.loaded)
      get();
  }, [courses.loaded, get]);

  useEffect(() => {
    setLoading(courses.loading);
  }, [courses.loading, setLoading]);

  return (
      <Courses courses={courses.data} />
  );
}
