import React, { FC } from 'react';
import { Room, Call } from '@material-ui/icons';
import { Colors } from '../../core';
import { Container } from '../Container';
import { Divider } from '../Divider';
import styled from '@emotion/styled';
import { Media } from '../Media';

export const SocialMedia = styled.a({
    padding: 0,
    border: 'none',
    background: 'transparent',
    color: '#fff',
    cursor: 'pointer',
    transition: 'color 0.2s',
    '&:hover': {
        color: Colors.Primary
    }
});

export const Footer: FC = () => {
    return (
        <div style={{
            background: Colors.TextDimBlue,
            padding: '60px 0'
        }}>
          <Media>
            {(matches: any) => (
              <Container>
                <Divider style={{
                  marginBottom: 35,
                  opacity: .1
                }} />
                <div style={{
                  display: 'flex',
                  gap: 50
                }}>
                  {matches.large ? (
                    <div style={{ height: 270, borderRadius: 6, overflow: 'hidden', flex: 1 }}>
                      <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A0ac42b2b19a0d83baa343624accef9ff42ef1fb144b85e5a662bfc870285cda2&amp;source=constructor" width="100%" height="400" frameBorder="0" />
                    </div>
                  ) : null}
                  <div style={{ textAlign: 'left', flex: 1, color: '#fff', fontSize: 14 }}>
                    <h1 style={{
                      textTransform: 'uppercase',
                      color: Colors.Primary,
                      marginBottom: matches.large ? 50 : 30,
                      fontSize: 50
                    }}>
                      Proftime
                    </h1>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 20,
                      marginBottom: 25
                    }}>
                      <Room/>
                      <span>
                          г. Нур-Султан, ул. Ж. Тархана, д.9, н.п. 36
                      </span>
                    </div>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 20,
                      marginBottom: 25
                    }}>
                      <Call/>
                      <span>
                          27-37-01 <span style={{ opacity: .5, padding: '0 10px' }}>|</span>
                          8 (778) 555 73 78
                      </span>
                    </div>
                  </div>
                </div>
                <Divider style={{
                  marginTop: 35,
                  opacity: .1
                }} />
                <div style={{
                  padding: '25px 0',
                  paddingBottom: 0,
                  ...(matches.large ? {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 50,
                    justifyContent: 'space-between',
                  } : {
                    textAlign: 'center'
                  })
                }}>
                  <div style={{
                    fontSize: 14,
                    textTransform: 'uppercase',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                    ...(!matches.large ? {
                      marginBottom: 30,
                      flexDirection: 'column'
                    } : {})
                  }}>
                    <SocialMedia style={{ textTransform: 'uppercase' }} href={'/privacy.pdf'} target={'_blank'}>Политика конфиденциальности</SocialMedia>
                    <SocialMedia style={{ textTransform: 'uppercase' }} href={'/policy.pdf'} target={'_blank'}>Пользовательское соглашение</SocialMedia>
                  </div>
                  <div>
                      <span style={{
                        fontSize: 14,
                        color: '#fff',
                        opacity: .7
                      }}>Copyright © 2022 • PROFTIME</span>
                  </div>
                </div>
              </Container>
            )}
          </Media>
        </div>
    );
};
