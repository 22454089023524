import React, {FC, useState} from "react";
import {RecoverPassword} from "../../pages";
import validator from "validator";
import { authService } from "../../service";

export const RecoverPasswordContainer: FC = () => {
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);

    const handleSubmit = (email: string) => {
        setError(null);
        if (validator.isEmail(email ?? '')) {
            authService.recover(email)
            .then(({ data }) => {
                console.log("OK");
                setSuccess(true);
            })
            .catch(() => {
                setError('wrong_email');
            });
        } else {
            setError('bad_email');
        }
    }

    return (
        <RecoverPassword handleSubmit={handleSubmit} error={error} success={success} />
    );
}