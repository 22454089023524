export enum Colors {
    Primary = '#FFBF5F',
    YellowBG = '#FBD15B',
    Placeholder = '#8C635B',
    PlaceholderBG = '#FFF4EA',
    Border = '#FFE6E1',
    Divider = '#E9E9E9',
    TextDimBlue = '#131135',
    GreyText = '#67748E',
    LightBlue = '#17C1E8'
}
