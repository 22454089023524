import { HomeContainer, AboutContainer, PolicyContainer } from '../../containers';
import { NewPasswordContainer } from '../../containers/NewPassword';

export const IndexRoutes = [
  {
    path: '/',
    component: HomeContainer,
    template: null,
    noAuth: true,
    fullHeight: true
  },
  {
    path: '/about',
    component: AboutContainer,
    template: null,
    noAuth: true,
    fullHeight: true
  },
  {
    path: '/policy',
    component: PolicyContainer,
    template: null,
    noAuth: true,
    fullHeight: true
  },
  {
    path: '/user/password/reset/verify',
    component: NewPasswordContainer,
    template: null,
    fullHeight: true,
    noAuth: true,
    noTemplate: true,
  }
];
