import React, { FC, useEffect } from 'react';
import { UserPage } from '../../../pages/Admin/UserPage';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';
import { authService } from '../../../service';
import { message } from 'antd';
import { allCoursesAtom } from '../../../store/modules/courses';

export const AddUserContainer: FC = () => {
  const [, { set: setLoading }] = useAtom(loadingAtom);
  const [courses, { get: getCourses }] = useAtom(allCoursesAtom);

  useEffect(() => {
    getCourses();
  }, [getCourses]);
  
  const handleAdd = (data: any) => {
    setLoading(true);
    authService.signUp(data)
      .then(async () => {
        await message.success('Сохранено!');
      })
      .catch(async () => {
        message.error('Что-то пошло не так');
      })
      .finally(() => setLoading(false));
  };

  return <UserPage handleAdd={handleAdd} courses={courses.data} />;
}
