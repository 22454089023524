import {
  AdminAddCourseContainer,
  AdminAddUserContainer, AdminEditCourseContainer, AdminEditUserContainer,
  AdminIndexContainer,
  AdminUsersContainer,
} from '../../containers/Admin';

export const AdminRoutes = [
  {
    path: '/admin',
    component: AdminIndexContainer,
    template: null,
    noAuth: true,
    fullHeight: true,
  },
  {
    path: '/admin/course',
    component: AdminAddCourseContainer,
    template: null,
    noAuth: true,
    fullHeight: true,
  },
  {
    path: '/admin/course/:id',
    component: AdminEditCourseContainer,
    template: null,
    noAuth: true,
    fullHeight: true,
  },
  {
    path: '/admin/users',
    component: AdminUsersContainer,
    template: null,
    noAuth: true,
    fullHeight: true,
  },
  {
    path: '/admin/users/:page',
    component: AdminUsersContainer,
    template: null,
    noAuth: true,
    fullHeight: true,
  },
  {
    path: '/admin/user',
    component: AdminAddUserContainer,
    template: null,
    noAuth: true,
    fullHeight: true,
  },
  {
    path: '/admin/user/:id',
    component: AdminEditUserContainer,
    template: null,
    noAuth: true,
    fullHeight: true,
  },
];
