import {
  ProfileCertificatesContainer,
  ProfileCoursesContainer,
  ProfileTemplate, ProfileTestsContainer,
} from '../../containers/Profile';

export const UserRoutes = [
  {
    path: '/profile',
    redirect: '/profile/courses'
  },
  {
    path: '/profile/courses',
    component: ProfileCoursesContainer,
    template: ProfileTemplate,
    noAuth: true,
    fullHeight: true,
  },
  {
    path: '/profile/certificates',
    component: ProfileCertificatesContainer,
    template: ProfileTemplate,
    noAuth: true,
    fullHeight: true,
  },
  {
    path: '/profile/tests',
    component: ProfileTestsContainer,
    template: ProfileTemplate,
    noAuth: true,
    fullHeight: true,
  },
];
