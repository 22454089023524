import React, {FC} from "react";
import {Props} from "./props";
import {Colors, getImage} from "../../core";
import {ArrowRightAltSharp} from "@material-ui/icons";
import { useHistory } from 'react-router-dom';

export const CourseCard: FC<Props> = ({ item, onClick: _, style, ...rest }: Props) => {
  const router = useHistory();
    return (
        <div
          onClick={() => router.push(`/course/${item.id}`)}
          css={{
            cursor: 'pointer',
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.05)'
            }
          }}
          {...rest}>
            <img
                src={getImage(item?.title_image)}
                alt={item?.title}
                style={{
                    width: '100%',
                    display: 'block',
                    boxShadow: '0px 6px 15px rgba(57, 60, 72, 0.05)',
                    borderRadius: 10
                }}
            />
            <h2
                style={{
                    fontSize: 20,
                    lineHeight: 1.5,
                    textAlign: 'left',
                    fontWeight: 'bold',
                    margin: 0,
                    marginBottom: 10,
                    marginTop: 24
                }}
            >{item?.title}</h2>
            <p style={{
                margin: 0,
                color: Colors.GreyText,
                textAlign: 'left',
                marginBottom: 12,
                fontSize: 16
            }}>{item?.short_description}</p>
            <div style={{ textAlign: 'left' }}>
                <button style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    fontFamily: 'inherit',
                    background: 'transparent',
                    border: 'none',
                    padding: 0,
                    fontSize: 16,
                    color: Colors.LightBlue
                }}>
                    <span>Подробнее</span>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.3869 6.13636C12.3869 5.87074 12.2853 5.61293 12.0978 5.43324L7.01187 0.347301C6.82437 0.159801 6.56656 0.0504258 6.30093 0.0504258C6.03531 0.0504258 5.78531 0.159801 5.59781 0.347301L5.01187 0.933239C4.82437 1.11293 4.715 1.37074 4.715 1.63636C4.715 1.90199 4.82437 2.1598 5.01187 2.33949L7.30093 4.63636H1.80093C1.23843 4.63636 0.886871 5.10511 0.886871 5.63636V6.63636C0.886871 7.16761 1.23843 7.63636 1.80093 7.63636H7.30093L5.01187 9.92543C4.82437 10.1129 4.715 10.3707 4.715 10.6364C4.715 10.902 4.82437 11.1598 5.01187 11.3473L5.59781 11.9332C5.78531 12.1129 6.03531 12.2223 6.30093 12.2223C6.56656 12.2223 6.82437 12.1129 7.01187 11.9332L12.0978 6.8473C12.2853 6.6598 12.3869 6.40199 12.3869 6.13636Z" fill="#17C1E8"/>
                    </svg>

                </button>
            </div>
        </div>
    );
}
