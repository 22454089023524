import React, { FC } from 'react';
import { Props } from './props';
import { Colors, getCertificate, getImage } from '../../core';
import { Button } from '../Button';
import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { useAtom } from '@reatom/react';
import { userAtom } from '../../store/modules/user';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Media } from '../Media';

export const CoursePriceCard: FC<Props> = ({ 
	course,
	purchased,
	style,
	handlePurchase,
	noControl,
	...rest
}: Props) => {
  const [user] = useAtom(userAtom);
  const router = useHistory();

  return (
    <Media>
      {(matches: any) => (
        <div
          style={{
            maxWidth: 400,
            background: '#fff',
            borderRadius: 6,
            padding: 10,
            boxShadow: '0px -4px 25px rgba(57, 60, 72, 0.25)',
            ...style
          }}
          {...rest}
        >
          <img src={getImage(course?.title_image)} alt={course?.title} style={{ width: '100%', borderRadius: 6 }} />

          <div className={'p-2'}>
            {!purchased ? (
              <div className={classNames('mb-3 mt-1 align-items-end', { 'd-flex': matches.large })} style={{ gap: 20 }}>
                <span style={{
                  display: 'inline-block',
                  fontSize: 30,
                  background: Colors.TextDimBlue,
                  padding: '10px 20px',
                  borderRadius: 6,
                  color: '#fff',
                }}>{course ? `${(course?.price / 100) - ((course?.price / 100) * course?.discount)}₸` : <Skeleton.Input style={{ width: 100, margin: 0 }} />}</span>
                {course?.discount > 0 ? (
                  <div
                    className={classNames('d-flex', { 'mt-2': !matches.large })}
                    style={{
                      gap: 10,
                      border: `1px solid ${Colors.Primary}`,
                      padding: '7px 10px',
                      fontSize: 18,
                      color: Colors.Placeholder,
                      borderRadius: 6
                    }}
                  >
                    <span style={{ textDecoration: 'line-through' }}>{course ? `${course?.price / 100}₸` : <Skeleton.Input style={{ width: 100 }} />}</span>
                    <span>{course ? `Скидка ${course?.discount * 100}%` : <Skeleton.Input style={{ width: 100 }} />}</span>
                  </div>
                ) : null}
              </div>
            ) : null}
            {!noControl ? (
              <>
                {!purchased ? (
                  <Button onClick={() => user.authenticated ? handlePurchase() : router.push('/auth/signin')}>{course ? 'Купить' : <Skeleton.Input style={{ width: 100 }} />}</Button>
                ) : (course?.passed ? (
                  <a
                    href={getCertificate(course?.cert)}
                    target={'_blank'}
                    css={{
                      '&:hover': {
                        color: 'initial'
                      }
                    }}
                  >
                    <Button
                      className={'d-flex align-items-center justify-content-center mt-3'}
                    >
                    	<EyeOutlined className={'mr-2'} style={{ fontSize: 20 }}/>
						<span>
							Результат
						</span>
                    </Button>
                  </a>
                ) : (
					<Button
						onClick={() => router.push(`/course/${course?.id}/test`)}
						className={'mt-3'}
					>
						{course ? 'Пройти тест' : <Skeleton.Input style={{ width: 100 }} />}
					</Button>
                ))}
              </>
            ) : null}

            <span
				style={{
					fontSize: 24,
					lineHeight: 1.5,
					fontWeight: 'bold',
				}}
				className={'my-3 d-block'}
            >
              Этот курс включает:
            </span>

            <div>
              {course?.course_plans.map((n: any, i: number) => (
                <div 
					key={i}
					className={'d-flex align-items-start py-2'}
					style={{ color: Colors.TextDimBlue }}
				>
					<div 
						className={'px-3'} 
						style={{ 
							fontSize: 12,
							lineHeight: '25px',
						}}
					>
						<CheckOutlined />
					</div>
                  	
					<span style={{ 
						fontSize: 16,
						lineHeight: 1.5,
					}}>
						{n.name.replaceAll('_', ' ')}
					</span>
                </div>
              )) || <Skeleton paragraph={{ rows: 6 }} />}
            </div>
          </div>
        </div>
        )}
    </Media>
  );
};
