import React, {FC, useState} from "react";
import { Link, useHistory } from 'react-router-dom';
import { Input } from "../../components/Form/Input";
import { Button } from "../../components/Button";
import styled from "@emotion/styled";
import {Props} from "./props";
import {Container} from "../../components/Container";
import {Divider} from "../../components/Divider";
import {Error} from "../../components/Error";
import {Anchor} from "../../components/Anchor";
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../store/ui/RootTemplate/atoms';
import { Media } from '../../components/Media';
import { Colors } from '../../core';

const BottomNav = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 27px;
    a {
        color: #131135;
        font-size: 16px;
        line-height: 18px;
        margin-right: 21px;
        text-decoration: none;
    }
    a:hover {
        color: #FFBF5F;
    }
`;

export const SignUp: FC<Props> = ({ handleSubmit, error }: Props) => {
  const [loading] = useAtom(loadingAtom);

  const [lastName, setLastName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [position, setPosition] = useState('');
  const [organization, setOrganization] = useState('');
  const [education, setEducation] = useState('');

  const user = {
      name,
      lastName,
      email,
      password,
      passwordConfirmation,
      position,
      organization,
      education
  };

  const router = useHistory();

  return (
    <Media>
      {(matches: any) => (
        <div className={'text-center'} style={{ background: '#FFFCFC' }}>
          <Container size={'540px'}>
            <img
              src={'/images/proftime.png'}
              alt={'logo'}
              onClick={() => router.push('/')}
              style={{
                marginBottom: 50,
                marginTop: 120,
                cursor: 'pointer',
                ...(!matches.large ? {
                  height: 75
                } : {})
              }} />
            <p style={{
              fontSize: 16,
              lineHeight: '18px',
              marginTop: 0,
              marginBottom: 50
            }}>
              Извлеките максимум пользы из своей профессиональной жизни
            </p>
            {error ? (
              <Error style={{ marginBottom: 30 }} message={error} />
            ) : null}
            <form
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 38
              }}
              onSubmit={(e) => {
                e.preventDefault();
                !loading.status ? handleSubmit(user) : void 0;
              }}
            >
              <Input disabled={loading.status} type={'text'} onChange={(e) => setLastName(e.currentTarget.value)} placeholder='Фамилия' />
              <Input disabled={loading.status} type={'text'} onChange={(e) => setName(e.currentTarget.value)} placeholder='Имя' />
              <Input disabled={loading.status} type={'text'} onChange={(e) => setEmail(e.currentTarget.value)} placeholder='Электронный адрес' />
              <Input disabled={loading.status} type={'password'} onChange={(e) => setPassword(e.currentTarget.value)} placeholder='Пароль' />
              <Input disabled={loading.status} type={'password'} onChange={(e) => setPasswordConfirmation(e.currentTarget.value)} placeholder='Повторите Пароль' />
              <Input disabled={loading.status} type={'text'} onChange={(e) => setPosition(e.currentTarget.value)} placeholder='Должность' />
              <Input disabled={loading.status} type={'text'} onChange={(e) => setOrganization(e.currentTarget.value)} placeholder='Наименование организации' />
              <Input disabled={loading.status} type={'text'} onChange={(e) => setEducation(e.currentTarget.value)} placeholder='Образование (высшее или средне-специальное)' />
              <p style={{
                fontSize: 16,
                lineHeight: '18px',
                textAlign: 'left'
              }}>
                Вы соглашаетесь с <a href={'/policy.pdf'} target={'_blank'}><Anchor>Пользовательским соглашением</Anchor></a> “компании” и <a href={'/privacy.pdf'} target={'_blank'}><Anchor>Политикой конфиденциальности</Anchor></a>.
              </p>
              <Button disabled={loading.status} style={{ fontSize: 20, color: '#fff' }} onClick={() => handleSubmit(user)}>
                Зарегистрироваться
              </Button>
            </form>
            <Divider />
            <BottomNav>
              <div className={'mb-5'}>
                <span style={{
                  fontSize: 16,
                  lineHeight: '18px'
                }}>
                 Вернуться к <Link to='/auth/signin' style={{ color: Colors.Primary }}>Войти</Link>
                </span>
              </div>
            </BottomNav>
          </Container>
        </div>
      )}
    </Media>
  );
}
