import React, {FC} from "react";
import {Props} from "./props";
import {Colors, getImage} from "../../core";
import { Media } from '../Media';

export const BenefitCard: FC<Props> = ({ item, ...rest }: Props) => {
    return (
        <div {...rest}>
          <Media>
            {(matches: any) => (
              <>
                {matches.large ? (
                  <img
                    src={getImage(item?.image)}
                    alt={item?.title}
                    style={{
                      width: '100%',
                      display: 'block',
                      borderRadius: 10,
                    }}
                  />
                ) : null}

                <div style={{
                  position: 'relative',
                  marginTop: matches.large ? -70 : 0,
                  padding: matches.large ? '0 35px' : 0
                }}>
                  <div style={{
                    borderRadius: '100%',
                    position: 'absolute',
                    ...(matches.large ? {
                      width: 60,
                      height: 60,
                      top: -30,
                    } : {
                      width: 90,
                      height: 90,
                      top: -45
                    }),
                    left: '50%',
                    transform: 'translateX(-50%)',
                    boxShadow: '0px 6px 15px rgba(57, 60, 72, 0.15)',
                    background: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                    <img src={item?.icon} alt={'icon'} />
                  </div>
                  <div style={{
                    background: '#fff',
                    borderRadius: 10,
                    boxShadow: '0px 6px 15px rgba(57, 60, 72, 0.2)',
                    padding: matches.large ? 15 : 35,
                    textAlign: 'center',
                  }}>
                    <h2
                      style={{
                        fontSize: matches.large ? 14 : 18,
                        lineHeight: 1.5,
                        fontWeight: 'bold',
                        margin: 0,
                        marginBottom: matches.large ? 5 : 10,
                        marginTop: matches.large ? 35 : 50,
                      }}
                    >{item?.title}</h2>
                    <p style={{
                      margin: 0,
                      color: Colors.GreyText,
                      marginBottom: 12,
                      fontSize: matches.large ? 13 : 16,
                      lineHeight: 1.5,
                    }}>{item?.description}</p>
                  </div>
                </div>
              </>
            )}
          </Media>
        </div>
    );
}
