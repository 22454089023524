import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../store/ui/RootTemplate/atoms';
import { Test } from '../../pages';
import { testsService } from '../../service';
import { message } from 'antd';
import { courseAtom } from '../../store/modules/courses';

export const TestContainer: FC = () => {
	const [loading, { set: setLoading }] = useAtom(loadingAtom);

	const { id } = useParams<{ id: any }>();
	const [quiz, setQuiz] = useState<ReadonlyArray<any>>([]);
	const [course, { get: getCourse }] = useAtom(courseAtom);
	const [finished, setFinished] = useState<any>(null);
	const [errMessage, setErrMessage] = useState('');

	useEffect(() => {
		getCourse(id);
	}, [getCourse, id]);

  useEffect(() => {
    setLoading(true);
    testsService.start(id)
      .then(res => {
        setQuiz(res.data.questions);
      })
      .catch(async (err) => {
        if (err.response.data.message) {
          setErrMessage(err.response.data.message);
        }
        await message.error(err.response.data.message || "Что-то пошло не так. Попробуйте позже");
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  const handleFinish = (data: any) => {
    const payload = data.reduce((accumulator: any, n: any) => {
      const answerId = n.answers.filter((m: any) => m.checked)[0].id;
      return {
        ...accumulator,
        [n.id]: answerId,
      };
    }, {});
    setLoading(true);
    testsService.finish(id, payload)
      .then(res => {
        setFinished(res.data);
      })
      .catch(async () => {
        await message.error("Что-то пошло не так, попробуйте позже!");
      })
      .finally(() => setLoading(false));
  };

	return !loading.status && quiz ? (
		<Test
			questions={quiz} 
			handleFinish={handleFinish}
			title={course?.data?.course?.title || ''} 
			finished={finished} 
			errMessage={errMessage} 
		/>
	) : null;
}
