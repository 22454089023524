import { RecoverPasswordContainer, SignInContainer, SignUpContainer } from '../../containers';

export const AuthRoutes = [
  {
    path: '/auth/signin',
    component: SignInContainer,
    template: null,
    noAuth: true,
    fullHeight: true,
    noTemplate: true
  },
  {
    path: '/auth/signup',
    component: SignUpContainer,
    template: null,
    noAuth: true,
    fullHeight: true,
    noTemplate: true
  },
  {
    path: '/auth/recover-password',
    component: RecoverPasswordContainer,
    template: null,
    noAuth: true,
    fullHeight: true,
    noTemplate: true
  }
];
