import React, { FC, useEffect, useState } from 'react';
import {Props} from "./props";
import { Colors } from '../../core';
import { Container } from '../../components/Container';
import { Media } from '../../components/Media';
import classNames from 'classnames';
import { Alert, Card } from 'antd';
import hexToRgba from 'hex-to-rgba';
import { Button } from '../../components/Button';
import { RightOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { Anchor } from '../../components/Anchor';

export const Test: FC<Props> = ({ 
	questions,
	handleFinish,
	title,
	finished,
	errMessage,
	...rest
}: Props) => {
  const [questionsState, setQuestionsState] = useState(questions);
  const [current, setCurrent] = useState(0);
  const n = questionsState[current];

  const { id } = useParams<{ id: any }>();
  const router = useHistory();

  useEffect(() => {
    setQuestionsState(questions);
  }, [questions]);

  const handleChoose = (itemIndex: number, variantIndex: number) => () => {
    setQuestionsState(prev => prev.map((n, i) => {
      if (i === itemIndex) {
        return {
          ...n,
          answers: n.answers.map((m: any, im: number) => {
            if (im === variantIndex) {
              return ({ ...m, checked: true });
            } return ({ ...m, checked: false });
          })
        };
      } return n;
    }));
  }

  return (
    <Media>
      {(matches: any) => (
        <div {...rest}>
          <section
            style={{
              paddingTop: matches.large ? 200 : 40,
              paddingBottom: matches.large ? 120 : 30,
              background: Colors.TextDimBlue
            }}
          >
            <Container className={classNames({ 'd-flex': matches.large }, 'justify-content-between')}>
              <div style={{
                maxWidth: matches.large ? 670 : '100%',
                width: '100%'
              }}>
                <h1 style={{
                  margin: 0,
                  fontSize: 50,
                  lineHeight: '57px',
                  color: Colors.YellowBG
                }}>
					Тест
				</h1>

                <p style={{
                  fontSize: 17,
                  lineHeight: '27px',
                  color: '#fff',
                  maxWidth: 400
                }}>
					Тест курса {title && `"${title}"`}
				</p>
              </div>
            </Container>
          </section>
          <Container style={{ marginTop: matches.large ? -100 : 20, marginBottom: 50 }}>
            {questions.length && !finished ? (
              <Card style={{ borderRadius: 6, padding: matches.large ? 25 : 0, boxShadow: '0 5px 15px rgba(0,0,0,.15)' }}>
                <div className={classNames('align-items-center', 'mb-4', { 'flex-column': !matches.large, 'd-flex': matches.large })}>
                  	<span style={{ 
						background: Colors.YellowBG, 
						fontSize: 20,
						fontWeight: 'bold',
						padding: '5px 15px',
						borderRadius: 6,
						marginRight: 25,
					}}>
						{current+1}
					</span>

					<p 
				  		className={classNames('m-0', { 'mt-3': !matches.large })}
						style={{ fontSize: 20, fontWeight: 'bold' }}
					>
						{n?.content}
					</p>
                </div>
                <div style={{
                  display: 'flex',
                  gap: 1,
                  background: hexToRgba(Colors.Placeholder, .1),
                  border: `1px solid ${hexToRgba(Colors.Placeholder, .1)}`,
                  borderRadius: 6,
                  overflow: 'hidden',
                  flexDirection: !matches.large ? 'column' : 'row'
                }}>
                  {n?.answers.map((m: any, im: number) => (
                    <label
                      className={'py-3 px-4'}
                      key={`${n.id}_${im}`}
                      style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        gap: 15,
                        fontSize: 18,
                        cursor: 'pointer',
                        transition: 'background 0.2s',
                        background: m.checked ? hexToRgba(Colors.LightBlue, .08) : '#fff'
                      }}
                    >
                      <input
                        type={'radio'}
                        name={`question_${n?.id}`}
                        onChange={handleChoose(current, im)}
                      />
                      {m.content}
                    </label>
                  ))}
                </div>
                <div className="text-right mt-5">
                  {questionsState.length !== current + 1 ? (
                      <Button
                        className={'w-auto d-inline-flex align-items-center pr-3'}
                        style={{ fontSize: 16, padding: '7px 20px' }}
                        onClick={() => setCurrent(prev => prev + 1)}
                        disabled={n?.answers.filter((variant: any) => variant.checked).length === 0}
                      >
                        Далее
                        <RightOutlined className={'ml-2'} style={{ fontSize: 13 }} />
                      </Button>
                  ) : (
                    <Button
                      className={'w-auto d-inline-block'}
                      style={{ fontSize: 16, padding: '7px 20px' }}
                      onClick={() => handleFinish(questionsState)}
                      disabled={n?.answers.filter((variant: any) => variant.checked).length === 0}
                    >
                      Закончить
                    </Button>
                  )}
                </div>
              </Card>
            ) : (finished ? (
              <Card className={'text-center p-5 px-2'}>
                <span style={{
                  fontSize: 60,
                  fontWeight: 'bold',
                  color: finished?.is_success ? '#52c41a' : '#d9363e',
                  lineHeight: 1,
                }}>
                  {Math.round(finished?.score || 0)}<span style={{ fontSize: 30 }}>%</span>
                </span>
                <p 
					className={'mt-1 mb-4'} 
					style={{ fontSize: 18, fontWeight: 'bold' }}
				>
					{Math.round((finished?.score * questions.length) / 100)}/{questions.length} вопросов
				</p>
				
                <p style={{ fontSize: 18 }}>
					{finished?.is_success ? 'Вы успешно прошли тест' : 'Вы провалили тест'}
				</p>

              </Card>
            ) : (errMessage ? (
              <>
                <Alert message={
                  <div>
                    <span style={{ fontSize: 16 }}>{errMessage}</span>
                  </div>
                } />
                <Anchor
                  onClick={() => router.push(`/course/${id}`)}
                  style={{
                    display: 'inline-block',
                    marginTop: 10,
                    fontSize: 14,
                    color: Colors.TextDimBlue,
                    cursor: "pointer",
                    textDecoration: 'underline',
                    marginLeft: 15
                  }}
                >Назад</Anchor>
              </>
            ) : null))}
          </Container>

        </div>
      )}
    </Media>
  );
}
