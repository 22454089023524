import { PaymentSuccess, PaymentFailure } from '../../containers';

export const PaymentRoutes = [
  {
    path: '/payment-processing/success',
    component: PaymentSuccess,
    template: null,
    noAuth: true,
    fullHeight: true,
  },
  {
    path: '/payment-processing/failure',
    component: PaymentFailure,
    template: null,
    noAuth: true,
    fullHeight: true,
  },
];
