import React, { FC } from 'react';

import { useHistory } from 'react-router-dom';
import {Container} from "../Container";
import {Colors} from '../../core';
import { userAtom } from '../../store/modules/user';
import { useAtom } from '@reatom/react';
import { Media } from '../Media';
import { CSSTransition } from 'react-transition-group';
import { MenuOutlined } from '@ant-design/icons';
import { Props } from './props';
import { MobileNavigation } from './libs/MobileNavigation';
import { ProfileNavigation } from './libs/ProfileNavigation';
import { MenuNavigation } from './libs/MenuNavigation';
import { CloseOutlined } from '@material-ui/icons';

export const Header: FC<Props> = ({ showMenu, setShowMenu }: Props) => {

    const history = useHistory();

    return (
      <Media>
        {(matches: any) => (
          <>
            <Container
              style={{
                zIndex: 999,
                ...(matches.large ? {
                  position: 'fixed',
                  width: '100%',
                  top: 35,
                  left: '50%',
                  transform: 'translateX(-50%)',
                } : {
                  position: 'sticky',
                  top: 0,
                  margin: 0,
                  padding: 0
                })
              }}
              size={matches.large ? '1170px' : '100%'}
            >
              <div style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                color: Colors.TextDimBlue,
                fontWeight: 500,
                fontSize: 18,
                background: '#fff',
                ...(matches.large ? {
                  boxShadow: '0px 5px 15px rgba(174, 174, 174, 0.3)',
                  borderRadius: 6,
                  padding: '15px 30px',
                } : {
                  padding: '20px 25px',
                })
              }}>
                {!matches.large ? (
                  <div
                    style={{
                      fontSize: 28,
                      paddingRight: 20,
                      marginRight: 10,
                      position: 'relative',
                      width: 50,
                      cursor: 'pointer'
                    }}
                    onClick={() => setShowMenu(prev => !prev)}
                  >
                    <div style={{ position: 'absolute', transform: 'translateY(-50%) translateX(-3px)', display: 'flex', alignItems: 'center' }}>
                      <CSSTransition
                        in={!showMenu}
                        transitionEnterTimeout={300}
                        transitionLeaveTimeout={300}
                        unmountOnExit
                        timeout={500}
                        onEnter={() => false}
                        onExited={() => false}
                        classNames={{
                          enter: 'animate__animated animate__rotateIn',
                          exit: 'animate__animated animate__zoomOut'
                        }}
                      >
                        <MenuOutlined style={{ position: 'absolute' }} />
                      </CSSTransition>
                      <CSSTransition
                        in={showMenu}
                        transitionEnterTimeout={300}
                        transitionLeaveTimeout={300}
                        unmountOnExit
                        timeout={500}
                        onEnter={() => false}
                        onExited={() => false}
                        classNames={{
                          enter: 'animate__animated animate__rotateIn',
                          exit: 'animate__animated animate__zoomOut'
                        }}
                      >
                        <CloseOutlined style={{ position: 'absolute', left: -3, fontSize: 35 }} />
                      </CSSTransition>
                    </div>

                  </div>
                ) : null}
                <div
                  style={{
                    cursor: 'pointer',
                    ...(matches.large ? {
                      marginRight: 100,
                    } : {
                      flex: 1,
                    })
                  }}
                  onClick={() => history.push('/')}
                >
                  {matches.large ? (
                    <img src="/images/proftime.png" alt="logo" style={{ height: 50, padding: '5px 0' }} />
                  ) : (
                    <img src="/images/logo.png" alt="logo" style={{ height: matches.small ? 40 : 50, padding: '5px 0' }} />
                  )}
                </div>

                {matches.large ? (
                  <div style={{
                    ...(matches.large ? {
                      display: 'flex',
                      alignItems: 'center',
                      gap: 60,
                      marginRight: 'auto',
                    } : {})
                  }}>
                    <MenuNavigation />
                  </div>
                ) : null}

                <ProfileNavigation />
              </div>
            </Container>

            <MobileNavigation showMenu={showMenu} setShowMenu={setShowMenu} />
          </>
        )}
      </Media>
    );
};
