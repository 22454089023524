import React, { FC } from 'react';
import { Colors } from '../../../../core';
import { Link, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Props } from './props';
import { MenuNavigation } from '../MenuNavigation';
import { NavigationLink } from '../NavigationLink';
import { useAtom } from '@reatom/react';
import { userAtom } from '../../../../store/modules/user';
import { Container } from '../../../Container';
import { Divider } from 'antd';
import hexToRgba from 'hex-to-rgba';
import { LoginOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';

export const MobileNavigation: FC<Props> = ({ showMenu, setShowMenu, ...rest }: Props) => {
  const [user] = useAtom(userAtom);
  const router = useHistory();

  return (
    <CSSTransition
      in={showMenu}
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
      unmountOnExit
      timeout={500}
      onEnter={() => setShowMenu(true)}
      onExited={() => setShowMenu(false)}
      classNames={{
        enter: 'animate__animated animate__slideInDown',
        exit: 'animate__animated animate__slideOutUp'
      }}
    >
      <div
        style={{
          background: Colors.TextDimBlue,
          color: '#fff',
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 98,
          overflow: 'auto',
          padding: '90px 0',
          paddingBottom: 150,
          display: 'flex',
          flexDirection: 'column'
        }}
        {...rest}
      >
        {showMenu ? (
          <div style={{ flex: 1 }}>
            <Divider
              className={'animate__animated animate__fadeInRight delay-300ms'}
              orientation={'right'}
              style={{
                color: '#fff',
                borderTopColor: hexToRgba('#fff', .2),
              }}
            >Меню навигации</Divider>
            <Container
              className={'animate__animated animate__fadeInLeft delay-300ms'}
              size={'100%'}
              style={{
                padding: '20px 50px',
                fontSize: 20,
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
              }}
            >
              <MenuNavigation />
            </Container>
            <Divider
              className={'animate__animated animate__fadeInRight delay-600ms'}
              orientation={'right'}
              style={{
                color: '#fff',
                borderTopColor: hexToRgba('#fff', .2),
                textTransform: 'capitalize'
              }}
            >{user.authenticated ? `${user.data?.name} ${user.data?.second_name}` : 'Авторизация'}</Divider>
            <Container
              className={'animate__animated animate__fadeInLeft delay-600ms'}
              size={'100%'}
              style={{
                padding: '20px 50px',
                fontSize: 20,
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
              }}
            >
              {user.authenticated ? (
                <>
                  <NavigationLink
                    style={{
                      textAlign: 'left',
                    }}
                    onClick={() => router.push('/profile')}
                  >
                    Мои курсы
                  </NavigationLink>
                  <NavigationLink
                    style={{
                      textAlign: 'left',
                    }}
                    onClick={() => router.push('/profile/certificates')}
                  >
                    Мои сертификаты
                  </NavigationLink>
                  {user.data.role ? (
                    <NavigationLink
                      style={{
                        textAlign: 'left',
                        color: Colors.Primary
                      }}
                      onClick={() => router.push('/admin')}
                    >
                      <span>Администрация</span>
                      <SettingOutlined className={'ml-3'} />
                    </NavigationLink>
                  ) : null}
                  <NavigationLink
                    style={{
                      textAlign: 'left',
                      color: '#ff4d4f'
                    }}
                    onClick={() => {
                      localStorage.removeItem('token');
                      window.location.replace('/');
                    }}
                  >
                    <span>Выйти</span>
                    <LoginOutlined className={'ml-3'} />
                  </NavigationLink>
                </>
              ) : (
                <>
                  <NavigationLink
                    style={{
                      textAlign: 'left',
                    }}
                    onClick={() => router.push('/auth/signin')}
                  >
                    <span>Войти</span>
                    <UserOutlined className={'ml-3'} />
                  </NavigationLink>
                  <NavigationLink
                    style={{
                      textAlign: 'left',
                    }}
                    onClick={() => router.push('/auth/signup')}
                  >
                    <span>Регистрация</span>
                    <LoginOutlined className={'ml-3'} />
                  </NavigationLink>
                </>
              )}
            </Container>
          </div>
        ) : null}
        <div className={'text-center animate__animated animate__fadeInUp delay-1s'}>
          <span style={{ color: hexToRgba('#fff', .7) }}>Copyright © 2018 • Lift Media Inc.</span>
        </div>
      </div>
    </CSSTransition>
  );
}
