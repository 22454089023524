import React, {FC, useMemo, useState} from "react";
import {NewPassword} from "../../pages";
import validator from "validator";
import { authService } from "../../service";
import { useLocation } from "react-router-dom";

export const NewPasswordContainer: FC = () => {
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const { search } = useLocation();

    const queryParams = new URLSearchParams(search);

    const email = useMemo(() => queryParams.get('email'), [queryParams]);
    const t = useMemo(() => queryParams.get('t'), [queryParams]);


    const handleSubmit = ({ password, confirmPassword }: any) => {
        console.log(password, confirmPassword)
        setError(null);
        setSuccess(false);
        if (password === confirmPassword) {
            authService.recoverApprove({ email, t, password })
                .then(({ data }) => {
                    console.log("OK"); 
                    setSuccess(true);
                })
                .catch((err) => {
                    setError(err?.response?.data?.message ?? 'Что-то пошло не так');
                });
        } else {
            setError('Пароли не совпадают');
        }
    }

    return (
        <NewPassword handleSubmit={handleSubmit} error={error} success={success} />
    );
}