import React, { FC } from 'react';
import ReactMedia from 'react-media';

export const Media: FC = ({ children }) => {
  return  (
    <ReactMedia queries={{
      small: "(max-width: 599px)",
      medium: "(min-width: 600px) and (max-width: 1169px)",
      large: "(min-width: 1170px)"
    }}>
      {children}
    </ReactMedia>
  );
}
