import {Colors} from "../../core";
import styled from '@emotion/styled';

export const Input = styled.input`
    border: 1px solid ${Colors.Border};
    margin-bottom: 21px;
    padding: 18px 23px;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    line-height: 18px;
    ::placeholder {
        color: ${Colors.Placeholder};
    }

    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
`;
