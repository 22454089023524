import React, { FC, useEffect } from 'react';
import { useAtom } from '@reatom/react';
import { allCoursesAtom } from '../../../store/modules/courses';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';
import { coursesService } from '../../../service';
import { message } from 'antd';
import { AdminIndex } from '../../../pages/Admin';

export const MainContainer: FC = () => {
  const [, { set: setLoading }] = useAtom(loadingAtom);
  const [courses, { get: getCourses }] = useAtom(allCoursesAtom);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  useEffect(() => {
    setLoading(courses.loading);
  }, [courses.loading, setLoading]);

  const handleDelete = (id: number) => {
    setLoading(true);
    coursesService.delete(id)
      .then(async () => {
        getCourses();
        await message.success('Успешно удалено!');
      })
      .finally(() => setLoading(false));
  }

  return <AdminIndex courses={courses.data} handleDelete={handleDelete} />;
};
