import React, { FC } from 'react';
import { Container } from '../../../components/Container';
import { Card, Skeleton } from 'antd';
import { Colors } from '../../../core';
import { Props } from './props';
import { CourseCard } from '../../../components/CourseCard';
import { Button } from '../../../components/Button';
import { Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';
import { Media } from '../../../components/Media';

export const Courses: FC<Props> = ({ courses, ...rest }: Props) => {
  const router = useHistory();
  const [loading] = useAtom(loadingAtom);

  return (
    <Media>
      {(matches: any) => (
        <div {...rest}>
          <Card style={{
            boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)',
            borderRadius: 8,
            border: 'none',
            padding: matches.large ? 20 : 0,
          }}>
        <span style={{
          fontSize: 23,
          fontWeight: 'bold',
          color: Colors.TextDimBlue
        }}>Мои курсы</span>
            {courses.map((n, i) => (
              <React.Fragment  key={i}>
                <div className={'py-3'} style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ flex: 1, fontSize: 18 }}>{n.title}</span>
                  <Button
                    css={{
                      fontSize: 16,
                      fontWeight: 500,
                      width: 'auto',
                      padding: '5px 15px',
                      background: 'transparent',
                      border: `1px solid ${Colors.YellowBG}`,
                      transition: 'color 0.2s, background 0.2s',
                      '&:hover': {
                        background: Colors.YellowBG,
                        color: '#fff'
                      }
                    }}
                    onClick={() => router.push(`/course/${n.id}`)}
                  >Перейти</Button>
                </div>
                {i !== courses.length - 1 ? (
                  <Divider />
                ) : null}
              </React.Fragment>
            ))}
            {courses?.length === 0 ? (
              <span className={'d-block mt-3'}>Пусто</span>
            ) : null}
            {loading.status ? (
              <Skeleton paragraph={{ rows: 4 }} />
            ) : null}
          </Card>
        </div>
      )}
    </Media>
  );
};
