import React, { FC, useEffect } from 'react';
import { ProfileCourses } from '../../../pages/Profile';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';
import { cabinetAtom } from '../../../store/modules/user';

export const CoursesContainer: FC = () => {
  const [cabinet, { get: getCabinet }] = useAtom(cabinetAtom);
  const [, { set: setLoading }] = useAtom(loadingAtom);

  useEffect(() => {
    getCabinet();
  }, [getCabinet]);

  useEffect(() => {
    setLoading(cabinet.loading);
  }, [cabinet.loading, setLoading]);

  return cabinet.loaded ? (
    // FIX
    <ProfileCourses courses={cabinet.data?.purchased_courses} />
  ) : null;
};
