import React, { FC, HTMLAttributes, ReactNode, useState } from 'react';
import { Container } from '../../../components/Container';
import { Props } from './props';
import { Colors, generateArray } from '../../../core';
import { Button } from '../../../components/Button';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined, SortAscendingOutlined,
  SortDescendingOutlined, UserOutlined,
} from '@ant-design/icons';
import { Input, Select, Skeleton, Tooltip } from 'antd';
import hexToRgba from 'hex-to-rgba';
import { Filter1Outlined } from '@material-ui/icons';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';
import { useHistory } from 'react-router-dom';

type RowProps = Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'children'> & { title?: ReactNode, edit?: ReactNode, remove?: ReactNode, last?: boolean };
const Row: FC<RowProps> = ({ title, edit, remove, style, last, ...rest }: RowProps) => {
  return (
    <div
      style={{
        display: 'flex',
        borderBottom: last ? 'none' : `1px solid #d7d7d7`,
        gap: 1,
        background: '#d7d7d7',
        ...(title ? {} : { fontWeight: 'bold' }),
        ...style
      }}
      {...rest}
    >
      <div style={{
        background: '#fff',
        flex: 1,
        padding: '20px 15px'
      }}>{title || 'Название'}</div>
      <div style={{
        background: '#fff',
        flex: '0 0 100px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
      }}>{edit || 'Изменить'}</div>
      <div style={{
        background: '#fff',
        flex: '0 0 100px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
      }}>{remove || 'Удалить'}</div>
    </div>
  );
}

export const Main: FC<Props> = ({ courses, handleDelete, ...rest }: Props) => {
  const [perPage, setPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState(0);
  const [loading] = useAtom(loadingAtom);

  const coursesSorted = sort !== 0 ? (
    sort === 1 ?
      [...courses].sort((a, b) => a.title.localeCompare(b.title)) :
      [...courses].sort((a, b) => b.title.localeCompare(a.title))
  ) : courses;
  const coursesFiltered = coursesSorted.filter(n => n.title.toLowerCase().includes(search.toLowerCase())).slice((currentPage-1) * perPage, currentPage * perPage);
  const pagesCount = Math.ceil(courses.length / perPage);

  const router = useHistory();

  return (
    <div {...rest}>
      <Container style={{ paddingTop: 200, marginBottom: 100 }}>
        <div style={{
          border: `1px solid #d7d7d7`,
          color: Colors.TextDimBlue,
          borderRadius: 8
        }}>
          <div className={'p-4 d-flex align-items-center'} style={{ borderBottom: `1px solid #d7d7d7` }}>
            <span style={{
              display: 'block',
              flex: 1,
              fontSize: 24,
              textTransform: 'uppercase',
              fontWeight: 'bold'
            }}>Курсы</span>
            <div className={'d-flex align-items-center'} style={{ gap: 10 }}>
              <Button
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  width: 'auto',
                }}
                onClick={() => router.push('/admin/users')}
              ><UserOutlined className={'mr-1'}/> Пользователи</Button>
              <Button
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  background: Colors.TextDimBlue,
                  color: '#fff',
                  border: 'none',
                  width: 'auto'
                }}
                onClick={() => router.push('/admin/course')}
              ><PlusOutlined/> Добавить курс</Button>
            </div>
          </div>
          {loading.status ? (
            <div className={'p-4'}>
              <Skeleton paragraph={{ rows: 10 }} />
            </div>
          ) : (
            <>
              <div className={'p-4'}>
                <div className={'d-flex align-items-center justify-content-between'}>
                  <div>
                    <span style={{ display: 'inline-block', marginRight: 20 }}>Показать</span>
                    <Select value={perPage} onChange={(newValue) => setPerPage(newValue)}>
                      <Select.Option value={5}>5</Select.Option>
                      <Select.Option value={10}>10</Select.Option>
                      <Select.Option value={20}>20</Select.Option>
                      <Select.Option value={50}>50</Select.Option>
                    </Select>
                  </div>
                  <div className={'d-flex align-items-center'}>
                    <span style={{ display: 'inline-block', marginRight: 20 }}>Поиск</span>
                    <Input value={search} onChange={(e) => setSearch(e.currentTarget.value)} placeholder={'Название курса...'} />
                  </div>
                </div>
              </div>
              <div className="px-4 pb-4 pt-0">
                <div style={{
                  border: `1px solid #d7d7d7`,
                  color: Colors.TextDimBlue,
                  borderRadius: 8,
                  overflow: 'hidden'
                }}>
                  <Row title={(
                    <>
                      {sort === 0 ? (
                        <div>
                          <Tooltip title={'Сортировать по алфавиту (A-Z)'}>
                        <span
                          className={'d-inline-flex align-items-center '}
                          css={{ gap: 10, transition: 'color 0.2s', cursor: 'pointer', '&:hover': { color: Colors.Primary } }}
                          onClick={() => setSort(1)}
                        >
                          <SortAscendingOutlined />
                          <span>Название</span>
                        </span>
                          </Tooltip>
                        </div>

                      ) : null}
                      {sort === 1 ? (
                        <div>
                          <Tooltip title={'Сортировать по алфавиту (Z-A)'}>
                        <span
                          className={'d-inline-flex align-items-center'}
                          css={{ gap: 10, transition: 'color 0.2s', cursor: 'pointer', '&:hover': { color: Colors.Primary } }}
                          onClick={() => setSort(2)}
                        >
                      <SortDescendingOutlined />
                      <span>Название</span>
                    </span>
                          </Tooltip>
                        </div>
                      ) : null}
                      {sort === 2 ? (
                        <div>
                          <Tooltip title={'Не сортировать'}>
                        <span
                          className={'d-inline-flex align-items-center'}
                          css={{ gap: 10, transition: 'color 0.2s', cursor: 'pointer', '&:hover': { color: Colors.Primary } }}
                          onClick={() => setSort(0)}
                        >
                          <CloseCircleOutlined />
                          <span>Название</span>
                        </span>
                          </Tooltip>
                        </div>

                      ) : null}
                    </>
                  )} edit={'Изменить'} remove={'Удалить'} style={{ fontWeight: 'bold' }} />
                  <div>
                    {coursesFiltered.map((n, i) => (
                      <Row
                        title={n.title}
                        key={i}
                        last={i === coursesFiltered.length - 1}
                        edit={
                          <Button
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                              width: 'auto'
                            }}
                            onClick={() => router.push(`/admin/course/${n.id}`)}
                          ><EditOutlined /></Button>
                        }
                        remove={
                          <Button
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                              width: 'auto'
                            }}
                            onClick={() => handleDelete(n.id)}
                          >
                            <DeleteOutlined />
                          </Button>
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className={'px-4 pb-4 d-flex align-items-center'} style={{ gap: 10 }}>
                {generateArray(pagesCount).map((page, i) => (
                  <Button
                    key={i}
                    css={{
                      fontSize: 14,
                      fontWeight: 500,
                      padding: '7px 15px',
                      width: 'auto',
                      ...(currentPage === page + 1 ? {} : {
                        background: 'transparent',
                        border: `1px solid ${Colors.TextDimBlue}`,
                        '&:hover': {
                          background: hexToRgba(Colors.TextDimBlue, .1)
                        }
                      })
                    }}
                    onClick={() => setCurrentPage(page + 1)}
                  >{page + 1}</Button>
                ))}
                {currentPage !== pagesCount ? (
                  <Button
                    css={{
                      fontSize: 14,
                      fontWeight: 500,
                      width: 'auto',
                      padding: '7px 15px',
                      background: 'transparent',
                      border: `1px solid ${Colors.TextDimBlue}`,
                      '&:hover': {
                        background: hexToRgba(Colors.TextDimBlue, .1)
                      }
                    }}
                    onClick={() => setCurrentPage(prev => prev + 1)}
                  >Вперед</Button>
                ) : null}
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
}
