import React, {FC, useState} from "react";
import { Link, useHistory } from 'react-router-dom';
import { Input } from "../../components/Form/Input";
import { Checkbox } from "../../components/Form/Checkbox";
import { CheckboxContainer } from "../../components/Form/CheckboxContainer";
import { Label } from "../../components/Form/Label";
import { Button } from "../../components/Button";
import styled from "@emotion/styled";
import {Props} from "./props";
import {Container} from "../../components/Container";
import {Divider} from "../../components/Divider";
import {Error} from "../../components/Error";
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../store/ui/RootTemplate/atoms';
import { Media } from '../../components/Media';
import { Colors } from '../../core';

const BottomNav = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 27px;
    a {
        color: #131135;
        font-size: 16px;
        line-height: 18px;
        margin-right: 21px;
        text-decoration: none;
    }
    a:hover {
        color: #FFBF5F;
    }
`;

export const SignIn: FC<Props> = ({ handleSubmit, error }: Props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading] = useAtom(loadingAtom);

    const user = {
        email,
        password
    };

    const router = useHistory();

    return (
      <Media>
        {(matches: any) => (
          <div className={'text-center'} style={{ background: '#FFFCFC' }}>
            <Container size={'540px'}>
              <img
                src={'/images/proftime.png'}
                alt={'logo'}
                onClick={() => router.push('/')}
                style={{
                  marginBottom: 50,
                  marginTop: 120,
                  cursor: 'pointer',
                  ...(!matches.large ? {
                    height: 75
                  } : {})
                }}
              />
              <h2 style={{
                fontSize: 24,
                lineHeight: '28px',
                marginBottom: 9
              }}>
                Добро пожаловать
              </h2>
              <p style={{
                fontSize: 16,
                lineHeight: '18px',
                marginTop: 0,
                marginBottom: 50
              }}>
                Не упустите свою возможность. Войдите в систему, чтобы всегда быть в курсе новых событий.
              </p>
              {error ? (
                <Error style={{ marginBottom: 30 }} message={
                  'Имя пользователя или пароль не распознаны. Повторите попытку или воспользуйтесь другим способом входа в систему.'
                } />
              ) : null}
              <form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 38
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  !loading.status ? handleSubmit(user) : void 0;
                }}
              >
                <Input disabled={loading.status} type={'text'} onChange={(e) => setEmail(e.currentTarget.value)} placeholder='Электронный адрес' />
                <Input disabled={loading.status} type={'password'} onChange={(e) => setPassword(e.currentTarget.value)} placeholder='Пароль' />
                <CheckboxContainer>
                  <Checkbox name='remember' type='checkbox'/>
                  <Label htmlFor='remember'>
                    Запомнить пароль
                  </Label>
                </CheckboxContainer>
                <Button style={{ fontSize: 20, color: '#fff' }} onClick={() => handleSubmit(user)} disabled={loading.status}>
                  ВОЙТИ
                </Button>
              </form>
              <Divider />
              <BottomNav className={'mb-5'}>
                <Link to='/auth/recover-password'>
                  <span style={{
                    fontSize: 16,
                    lineHeight: '18px'
                  }}>
                   Забыли пароль?
                  </span>
                </Link>
                <Link to='/auth/signup'>
                  <span style={{
                    fontSize: 16,
                    lineHeight: '18px',
                    color: Colors.Primary
                  }}>
                   Регистрация
                  </span>
                </Link>
              </BottomNav>
            </Container>
          </div>
        )}
      </Media>
    );
}
