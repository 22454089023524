import {instance} from "../core";
import axios from 'axios';

export const coursesService = {
  get: () => instance.get('/course/public'),
  getForAdmin: () => instance.get('/course/all'),
  getById: (id: number) => instance.get(`/course/userview/${id}`),
  getByIdAdmin: (id: number) => instance.get(`/course/${id}`),
  addCourse: (course: any) => instance.post('/course', course),
  search: (term: string) => instance.get(`/course/search?f=${term}`),
  create: (data: any) => instance.post('/course', data),
  delete: (id: number) => instance.post(`/course/${id}/delete`),
  update: (id: number, data: any) => instance.post(`/course/${id}`, data),
  purchase: (id: number) => instance.post(`/course/${id}/purchase`),
  upload: (id: number, data: any) => instance.post(`/course/${id}/uploadphoto`, data),
  certificate: (url: string) => instance.get(url.replace("https://proftime.kz", "")),
}

