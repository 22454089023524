import React, { FC, HTMLAttributes, useState } from 'react';
import {Colors} from "../../../core";
import {Container} from "../../../components/Container";
import {Textarea} from "../../../components/Form/Textarea";
import {MainFormInput} from "../../../components/Form/MainFormInput";
import {CompactButton} from "../../../components/Form/CompactButton";
import { Media } from '../../../components/Media';
import { message as notification } from 'antd';
import { Button } from '../../../components/Button';

type Props = HTMLAttributes<HTMLDivElement> & {
  readonly handleFeedback: (data: any) => void;
};

export const Form: FC<Props> = ({ style, handleFeedback, ...rest }: Props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const onSubmit = () => {
      if (name?.length && email?.length && message?.length) {
        handleFeedback({
          email,
          message: `Имя: ${name}\nСообщение: ${message}`
        });
        setName('');
        setEmail('');
        setMessage('');
      } else {
        notification.error("Заполните все поля");
      }
    };

    return (
        <Media>
          {(matches: any) => (
            <div style={{
              padding: matches.large ? '100px 0' : 0,
              paddingBottom: matches.large ? 150 : 50,
              textAlign: 'center',
              ...style
            }} {...rest}>
              <Container>
                <h1 style={{
                  fontSize: matches.large ? 50 : 30,
                  margin: 0,
                  fontWeight: 700,
                  color: Colors.TextDimBlue
                }}>Скажите привет!</h1>
                <p style={{
                  fontSize: matches.large ? 20 : 15,
                  margin: 0,
                  color: Colors.GreyText,
                  marginBottom: 80
                }}>Мы хотели бы поговорить с вами.</p>
                <div style={{
                  background: '#fff',
                  borderRadius: 10,
                  boxShadow: '0px 0px 15px rgba(57, 60, 72, 0.15)',
                  display: 'flex',
                  overflow: 'hidden'
                }}>
                  <div style={{
                    flex: matches.large ? '0 0 700px' : 1,
                    padding: matches.large ? 30 : 15,
                    textAlign: 'left'
                  }}>
                    <span style={{
                      color: Colors.TextDimBlue,
                      fontSize: 18,
                      fontWeight: 'bold',
                      marginBottom: 15,
                      display: 'block'
                    }}>Имя</span>
                    <MainFormInput placeholder={'Ф.И.О'} style={{ display: 'block', width: '100%', marginBottom: 20 }} onChange={(e) => setName(e.currentTarget.value)} />
                    <span style={{
                      color: Colors.TextDimBlue,
                      fontSize: 18,
                      fontWeight: 'bold',
                      marginBottom: 15,
                      display: 'block'
                    }}>E-mail</span>
                    <MainFormInput placeholder={'Электронный адрес'} style={{ display: 'block', width: '100%', marginBottom: 20 }} onChange={(e) => setEmail(e.currentTarget.value)} />
                    <span style={{
                      color: Colors.TextDimBlue,
                      fontSize: 18,
                      fontWeight: 'bold',
                      marginBottom: 15,
                      display: 'block'
                    }}>Сообщение</span>
                    <Textarea placeholder={'Задайте интересующий вас вопрос'} style={{ display: 'block', width: '100%', marginBottom: 25 }}  onChange={(e) => setMessage(e.currentTarget.value)} />
                    <Button
                      style={{
                        display: 'inline-block',
                        padding: matches.large ? '10px 25px' : '10px 25px',
                        color: Colors.TextDimBlue,
                        borderRadius: 4,
                        fontSize: 16,
                        fontWeight: 500,
                        border: 'none',
                        cursor: 'pointer',
                        width: 'auto'
                      }}
                      onClick={onSubmit}
                    >Отправить</Button>
                  </div>
                  {matches.large ? (
                    <div style={{
                      flex: 1,
                      backgroundImage: 'url(/images/form-image.png)',
                      backgroundSize: 'cover',
                    }} />
                  ) : null}
                </div>
              </Container>
            </div>
         )}
        </Media>
    );
}
