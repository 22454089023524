import {
  CoursesContainer,
  CourseContainer,
  TestContainer,
  CertificateContainer,
} from '../../containers';

export const CourseRoutes = [
  {
    path: '/courses',
    component: CoursesContainer,
    template: null,
    noAuth: true,
    fullHeight: true
  },
  {
    path: '/course/:id',
    component: CourseContainer,
    template: null,
    noAuth: true,
    fullHeight: true
  },
  {
    path: '/course/:id/test',
    component: TestContainer,
    template: null,
    noAuth: true,
    fullHeight: true
  },
  // {
  //   path: '/course/:id/certificate',
  //   component: CertificateContainer,
  //   template: null,
  //   noAuth: true,
  //   fullHeight: true
  // },
];
