import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL

const token = localStorage.getItem('token');

export const instance = axios.create({
    baseURL: API_URL,
    headers: token ? {
      Authorization: `Bearer ${token}`,
      } : {},
});
