import {
  AdminRoutes,
  AuthRoutes,
  CourseRoutes,
  IndexRoutes,
  PaymentRoutes,
  UserRoutes,
} from './paths';
import { Error404 } from '../containers';

export const Routes = [
  ...IndexRoutes,
  ...AuthRoutes,
  ...CourseRoutes,
  ...UserRoutes,
  ...AdminRoutes,
  ...PaymentRoutes,
  {
    path: '*',
    component: Error404,
    template: null,
    noAuth: true,
    fullHeight: true,
  }
];
