import React, {FC, useState} from "react";
import { Link, useHistory } from 'react-router-dom';
import { Input } from "../../components/Form/Input";
import { Button } from "../../components/Button";
import styled from "@emotion/styled";
import {Props} from "./props";
import {Container} from "../../components/Container";
import {Divider} from "../../components/Divider";
import {Error} from "../../components/Error";
import { Media } from '../../components/Media';
import { Colors } from '../../core';

const BottomNav = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 27px;
    a {
        color: #131135;
        font-size: 16px;
        line-height: 18px;
        margin-right: 21px;
        text-decoration: none;
    }
    a:hover {
        color: #FFBF5F;
    }
`;

export const RecoverPassword: FC<Props> = ({ handleSubmit, error, success }: Props) => {
    const [email, setEmail] = useState('');
    const router = useHistory();

    return (
      <Media>
        {(matches: any) => (
          <div className={'text-center'} style={{ background: '#FFFCFC', height: '100%' }}>
            <Container size={'540px'}>
              <img src={'/images/proftime.png'} alt={'logo'} onClick={() => router.push('/')} style={{
                marginBottom: 50,
                marginTop: 120,
                ...(!matches.large ? {
                  height: 75
                } : {})
              }} />
              <h2 style={{
                fontSize: 24,
                lineHeight: '28px',
                marginBottom: 9
              }}>
                Во-первых, давайте найдем ваш аккаунт
              </h2>
              <p style={{
                fontSize: 16,
                lineHeight: '18px',
                marginTop: 0,
                marginBottom: 50
              }}>
                Пожалуйста введите ваш e-mail или номер телефона
              </p>
              {error ? (
                <Error style={{ marginBottom: 30 }} message={
                  'Недопустимый электронный адрес. Введите адрес электронной почты в формате name@email.com'
                } />
              ) : null}
              {success ? (
                <Error style={{ marginBottom: 30 }} success message={
                  'Письмо для смены пароля успешно отправлено'
                } />
              ) : null}
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 38
              }}>
                <Input type={'text'} onChange={(e) => setEmail(e.currentTarget.value)} placeholder='Электронный адрес' />
                <Button style={{ fontSize: 20, color: '#fff' }} onClick={() => handleSubmit(email)}>
                  Восстановить пароль
                </Button>
              </div>
              <Divider />
              <BottomNav>
                <div>
                  <span style={{
                    fontSize: 16,
                    lineHeight: '18px'
                  }}>
                     Вернуться к <Link to='/auth/signin' style={{ color: Colors.Primary }}>Войти</Link>
                  </span>
                </div>
              </BottomNav>
            </Container>
          </div>
        )}
      </Media>
    );
}
