import React, { FC, HTMLAttributes } from 'react';
import { Media } from '../../../Media';
import { useHistory, useLocation } from 'react-router-dom';
import { NavigationLink } from '../NavigationLink';
import { useAtom } from '@reatom/react';
import { userAtom } from '../../../../store/modules/user';
import { Container } from '../../../Container';
import { Colors } from '../../../../core';

export const MenuNavigation: FC = () => {
  const [user] = useAtom(userAtom);

  const links = (matches: any) => [
    {
      path: '/',
      label: 'Главная'
    },
    {
      path: '/about',
      label: 'О нас'
    },
    {
      path: '/courses',
      label: 'Курсы'
    },
  ];

  const router = useHistory();
  const location = useLocation();

  return (
    <Media>
      {(matches: any) => (
        <>
          {links(matches).map((n, i) => (
            <NavigationLink
              onClick={() => router.push(n.path)}
              key={i}
              css={{
                ...(!matches.large ? {
                  textAlign: 'left',
                  ...(location.pathname === n.path ? {
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      display: 'block',
                      height: 2,
                      background: Colors.Primary,
                      width: 20,
                      position: 'absolute',
                      left: -30
                    }
                  } : {})
                } : {}),
                ...(location.pathname === n.path ? {
                  color: Colors.Primary
                } : {})
              }}
            >
              {n.label}
            </NavigationLink>
          ))}
        </>
      )}
    </Media>
  );
}
