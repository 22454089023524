import { Container } from '../../../components/Container';
import { Typography } from 'antd';
import { Button } from '../../../components/Button';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const Error404 = () => {
  const router = useHistory();
  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
      <Container size={'600px'}>
        <div className="text-center">
          <Typography.Title level={2} className={'mb-2'}>Ошибка 404</Typography.Title>
          <Typography.Paragraph className={'mb-5 mt-0'}>Страница не найдена</Typography.Paragraph>
          <Button
            style={{
              fontSize: 16,
              width: 'auto',
              margin: 'auto',
              padding: '10px 25px'
            }}
            onClick={() => router.push('/')}
          >Вернуться на главную</Button>
        </div>
      </Container>
    </div>
  );
}
