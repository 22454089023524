import styled from '@emotion/styled';
import { Accordion as MuiAccordion } from '@material-ui/core';
import { Colors } from '../../core';

export const Accordion = styled(MuiAccordion)`
  &::before {
    display: none;
  }
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
  & .MuiAccordionSummary-root {
    border: 1px solid ${Colors.TextDimBlue}!important;
    border-radius: 6px;
    flex-direction: row-reverse;
    padding: 7px 20px;
    gap: 30px;
  }
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
  & .MuiAccordionSummary-content {
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }
  & .MuiAccordionSummary-expandIcon {
    padding-left: 0;
    padding-right: 0;
    color: ${Colors.TextDimBlue};
  }
  & .MuiAccordionDetails-root {
    background: ${Colors.PlaceholderBG};
    display: block;
  }
  & .MuiCollapse-root {
    padding: 0 15px;
    border-radius: 0 0 8px 8px;
  }
`;
