import React, {FC, useEffect} from "react";
import {Home} from "../../pages";
import {useAtom} from "@reatom/react";
import { publicCoursesAtom, searchCoursesAtom } from '../../store/modules/courses';
import { loadingAtom } from '../../store/ui/RootTemplate/atoms';
import { useDebouncedCallback } from 'use-debounce';
import { feedbackService } from '../../service';
import { message } from 'antd';

export const HomeContainer: FC = () => {
  const [, { set: setLoading }] = useAtom(loadingAtom);
  const [courses, { get }] = useAtom(publicCoursesAtom);
  const [searchResult, { get: search, reset: resetSearch }] = useAtom(searchCoursesAtom);

  useEffect(() => {
      if (!courses.loaded)
        get();
  }, [courses.loaded, get]);

  useEffect(() => {
    setLoading(courses.loading);
  }, [courses.loading, setLoading]);

  const handleSearch = useDebouncedCallback((term: string) => {
    resetSearch();
    if (term.length > 3) {
      search(term);
    }
  }, 500);

  const benefits = [
      {
          title: 'Дистанционное обучение',
          description: 'Не нужно приходить в учебный центр для прохождения интересующего вас курса.',
          image: '/images/benefit.png',
          icon: '/images/benefitIcon.png',
      },
      {
          title: 'Профессиональные преподаватели',
          description: 'Неограниченный доступ к материалам после приобретения наших онлайн-курсов.',
          image: '/images/benefit2.png',
          icon: '/images/benefitIcon2.png',
      },
      {
          title: 'Катируемый сертификат',
          description: 'Оперативное получение документов по окончании курса (возможна отправка курьером).',
          image: '/images/benefit3.png',
          icon: '/images/benefitIcon3.png',
      },
  ];

  const handleFeedback = (data: any) => {
      feedbackService.create(data)
        .then((res) => {
          message.success("Отправлено! Мы с вами скоро свяжемся");
        })
        .catch(() => {
          message.error("Что-то пошло не так");
        });
  }

  return (
      <Home
        courses={courses.data}
        benefits={benefits}
        searchResult={searchResult.data}
        handleSearch={handleSearch}
        searchNotFound={searchResult.notFound}
        searchLoading={searchResult.loading}
        handleFeedback={handleFeedback}
      />
  );
}
