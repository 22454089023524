import {instance} from "../core";

export const usersService = {
  getAll: (page: number, term?: string | null) => instance.get(`/users?page=${page}${term ? '&f=' + term : ''}`),
  addUser: (user: any) => instance.post('/user', user),
  uploadAvatar: (data: any) => instance.post('/user/photo/upload', data),
  get: (id: number) => instance.get(`/user/${id}`),
  update: (id: number, data: any) => instance.post(`/user/${id}`, data),
  cabinet: () => instance.get(`/user/cabinet`),
}
