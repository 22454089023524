import React, { FC, useEffect, useState } from 'react';
import { UserPage } from '../../../pages/Admin/UserPage';
import { useAtom } from '@reatom/react';
import { loadingAtom } from '../../../store/ui/RootTemplate/atoms';
import { usersService } from '../../../service';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { allCoursesAtom } from '../../../store/modules/courses';

export const EditUserContainer: FC = () => {
  const [, { set: setLoading }] = useAtom(loadingAtom);
  const [user, setUser] = useState<any>(null);
  const [courses, { get: getCourses }] = useAtom(allCoursesAtom);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  const { id } = useParams<{ id: any }>();

  useEffect(() => {
    setLoading(true);
    usersService.get(id)
      .then(res => {
        setUser(res.data);
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  const handleUpdate = (data: any) => {
    setLoading(true);
    usersService.update(id, data)
      .then(async (res) => {
        await message.success('Сохранено!');
      })
      .catch(async () => {
        message.error('Что-то пошло не так');
      })
      .finally(() => setLoading(false));
  };

  return <UserPage handleUpdate={handleUpdate} courses={courses.data} item={user} />;
}
