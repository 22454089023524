export const recurseRoutes = (routesTree, parentPathNest = '', template = null, noAuth = null, fullHeight = null, noTemplate = null) => {
  const templateFinal = routesTree.template || template;
  const templateFinalWithFilter = routesTree.hasOwnProperty('template') ? routesTree.template : templateFinal;

  const noAuthFinal = routesTree.noAuth ?? noAuth;
  const fullHeightFinal = routesTree.fullHeight ?? fullHeight;
  const noTemplateFinal = routesTree.noTemplate ?? noTemplate;
  const noAuthFinalWithFilter = routesTree.hasOwnProperty('noAuth') ? routesTree.noAuth : noAuthFinal;
  const fullHeightFinalWithFilter = routesTree.hasOwnProperty('fullHeight') ? routesTree.fullHeight : fullHeightFinal;
  const noTemplateFinalWithFilter = routesTree.hasOwnProperty('noTemplate') ? routesTree.noTemplate : noTemplateFinal;
  return routesTree.routes ?
    [
      ...(routesTree.component ? [{ path: `${parentPathNest}${routesTree.path}`, component: routesTree.component, template: templateFinalWithFilter, noAuth: noAuthFinalWithFilter, fullHeight: fullHeightFinalWithFilter, noTemplate: noTemplateFinalWithFilter }] : []),
      ...routesTree.routes.map(subRoute => recurseRoutes(subRoute, `${parentPathNest}${routesTree.path}`, templateFinal, noAuthFinal, fullHeightFinal))
    ] :
    {
      path: `${parentPathNest}${routesTree.path}`,
      component: routesTree.component,
      template: templateFinalWithFilter,
      noAuth: noAuthFinalWithFilter,
      fullHeight: fullHeightFinalWithFilter,
      noTemplate: noTemplateFinalWithFilter,
      ...(routesTree.redirect ? { redirect: routesTree.redirect} : {})
    }
};
